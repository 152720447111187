import { IntegerField } from "../types/field";

export const CONTACT_PREFERENCE_EMAIL = 0;
export const CONTACT_PREFERENCE_PHONE = 1;
export const CONTACT_PREFERENCE_TEXT = 2;

export const ContactPreferenceLabel = {
  [CONTACT_PREFERENCE_EMAIL]: "Email",
  [CONTACT_PREFERENCE_PHONE]: "Phone",
  [CONTACT_PREFERENCE_TEXT]: "Text",
};

export const contactPreferenceField: IntegerField<{
  array: true;
  name: "contact_preference";
}> = {
  array: true,
  label: "Contact Preference",
  name: "contact_preference",
  type: "integer",
};
