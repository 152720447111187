import { StringField } from "../types/field";

export const auth0UserIdField: StringField<{
  final: true;
  name: "auth0_user_id";
}> = {
  final: true,
  label: "Auth0 User ID",
  name: "auth0_user_id",
  type: "string",
};
