import {
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Indicator,
  Stack,
  Text,
  Title,
  useMantineColorScheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import { useCallback } from "react";

import {
  OrganizationEntity,
  OrganizationUpdateType,
} from "../../../common/entities/organization.js";
import { ENTITLEMENT_IS_SERVICE_PROVIDER } from "../../../common/fields/entitlements.js";
import { organizationClient } from "../../../common/utils/entityClient.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { PrimaryColor } from "../../theme.js";
import { clientError } from "../../utils/clientError.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";
import { OrganizationModal } from "../Modals/OrganizationModal.js";
import { UpdateEntitlements } from "./OrganizationEntitlement.js";
import { OrganizationEntitlements } from "./OrganizationEntitlements.js";

interface CompanyOverviewProps {
  activeOrganization: OrganizationEntity;
}

export function CompanyOverview({ activeOrganization }: CompanyOverviewProps) {
  const { customer, setCustomer, setServiceProvider } = useActiveStates();
  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineColorScheme();
  const backgroundColor =
    theme.colorScheme === "dark" ? "org_primary.9" : "org_primary.0";

  // Use customer if available, otherwise use service provider

  const isCustomer = !(
    activeOrganization.entitlements as unknown as number[]
  )?.includes?.(ENTITLEMENT_IS_SERVICE_PROVIDER);

  const handleUpdateSubmit = useCallback(
    async (patch: OrganizationUpdateType) => {
      const updated = await organizationClient(clientError)
        .item(activeOrganization.id!)
        .update(patch);
      if (updated.success && updated.data.success) {
        if (isCustomer) {
          setCustomer(updated.data.data);
        } else {
          setServiceProvider(updated.data.data);
        }
      } else if (updated.success) {
        throw new Error(
          updated.data.success === false
            ? updated.data.errors.join(";")
            : `HTTP ${updated.status.toString(10)}`
        );
      } else {
        try {
          const errResponse = JSON.parse(updated.text);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errError: any = new Error(
            errResponse.errors?.join("; ") ?? updated.text
          );
          errError.errors = errResponse.errors;
          throw errError;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if ("errors" in e) {
            throw e;
          }
          throw new Error(
            `${e?.message ?? e}; ${
              updated.error ?? `HTTP ${updated.status.toString(10)}}`
            }`
          );
        }
      }
    },
    [setCustomer, setServiceProvider, activeOrganization?.id, isCustomer]
  );

  const formattedDate = new Date(
    activeOrganization.created_at ?? new Date()
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const handleUpdateEntitlements = useCallback<UpdateEntitlements>(
    async (operations) => {
      const patch = {
        entitlements: (
          (activeOrganization.entitlements as unknown as number[]) ?? []
        )
          .slice(0)
          .filter((x) => operations.remove_entitlement !== x),
        extend_entitlements: (
          (activeOrganization.extend_entitlements as unknown as number[]) ?? []
        )
          .slice(0)
          .filter((x) => operations.remove_extend_entitlement !== x),
      };
      if (typeof operations.add_entitlement === "number") {
        if (!patch.entitlements.includes(operations.add_entitlement)) {
          patch.entitlements.push(operations.add_entitlement);
        }
      }
      if (typeof operations.add_extend_entitlement === "number") {
        if (
          !patch.extend_entitlements.includes(operations.add_extend_entitlement)
        ) {
          patch.extend_entitlements.push(operations.add_extend_entitlement);
        }
      }
      const updated = await organizationClient(clientError)
        .item(activeOrganization.id!)
        .update(patch as unknown as Partial<OrganizationEntity>);
      if (updated.success && updated.data.success) {
        if (activeOrganization.id === customer?.id) {
          setCustomer(updated.data.data);
        } else {
          setServiceProvider(updated.data.data);
        }
      }
    },
    [activeOrganization, setCustomer, setServiceProvider, customer?.id]
  );

  return (
    <>
      <Card radius={0} px={0} pb="xl">
        {/* Color Banner */}
        <Card.Section bg={backgroundColor} h={150} />

        {/* Profile Section */}
        <Card.Section px="xl">
          <Group
            justify="space-between"
            align="flex-start"
            style={{ marginTop: -60 }}
          >
            <Group>
              <OrganizationAvatar
                style={{ border: "2px solid var(--mantine-color-body)" }}
                organization={activeOrganization}
                variant="filled"
                color={PrimaryColor}
                size={120}
                radius="md"
              />
              <Stack gap="xs" mt={-5}>
                <Title order={3} fw={700} mb="lg">
                  {activeOrganization.name ?? "Unnamed Organization"}
                </Title>
                <Group gap="xs">
                  <Indicator
                    color={activeOrganization.theme_primary_color ?? "#228BE6"}
                  />
                  <Text size="sm" c="dimmed">
                    {activeOrganization.theme_primary_color ?? "#228BE6"}
                  </Text>
                </Group>
              </Stack>
            </Group>
            <Button
              leftSection={<IconEdit size={16} />}
              onClick={open}
              variant="default"
            >
              Edit Organization
            </Button>
          </Group>
        </Card.Section>
        <Divider my="lg" />
        <Card.Section px="xl">
          {/* Organization Details */}
          <Grid>
            <Grid.Col span={12}>
              <Stack gap="xs">
                <Text fw={500} size="lg">
                  Organization Details
                </Text>
                <Text size="sm" c="dimmed">
                  Manage your organization's basic information
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text size="sm" fw={500} c="dimmed">
                Name
              </Text>
              <Text>{activeOrganization.name ?? "Unnamed Organization"}</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text size="sm" fw={500} c="dimmed">
                Handle
              </Text>
              <Text>@{activeOrganization.slug ?? "no-handle"}</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text size="sm" fw={500} c="dimmed">
                Domain
              </Text>
              <Text>
                {activeOrganization.primary_domain ?? "No domain set"}
              </Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text size="sm" fw={500} c="dimmed">
                Location
              </Text>
              <Text>
                {activeOrganization.primary_location ?? "No location set"}
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Text size="sm" fw={500} c="dimmed">
                Description
              </Text>
              <Text>
                {activeOrganization.description ?? "No description provided"}
              </Text>
            </Grid.Col>
          </Grid>
        </Card.Section>

        <Divider my="lg" />
        <Card.Section px="xl">
          {/* Metadata Section */}
          <Grid>
            <Grid.Col span={12}>
              <Stack gap="xs">
                <Text fw={500} size="lg">
                  Organization Metadata
                </Text>
                <Text size="sm" c="dimmed">
                  System-generated information about your organization
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text size="sm" fw={500} c="dimmed">
                Created Date
              </Text>

              <Text>{formattedDate ?? "Date not available"}</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text size="sm" fw={500} c="dimmed">
                Organization ID
              </Text>
              <Text>{activeOrganization.id ?? "ID not available"}</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text size="sm" fw={500} c="dimmed">
                Type
              </Text>
              <Text>{isCustomer ? "Client" : "Service Provider"}</Text>
            </Grid.Col>
          </Grid>
        </Card.Section>
        <Card.Section>
          <Divider my="lg" />
          <OrganizationEntitlements
            organization={activeOrganization}
            onUpdateEntitlements={handleUpdateEntitlements}
          />
        </Card.Section>
      </Card>

      <OrganizationModal
        opened={opened}
        onClose={close}
        onSubmit={async (patch) => {
          await handleUpdateSubmit(patch);
          close();
        }}
        type={
          activeOrganization.entitlements === 0
            ? "customer"
            : "service_provider"
        }
        editMode={true}
        initialData={activeOrganization}
      />
    </>
  );
}
