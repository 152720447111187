import {
  Box,
  Center,
  Group,
  Paper,
  RingProgress,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { IconArrowDownRight, IconArrowUpRight } from "@tabler/icons-react";
import { UserAvatar } from "../../components/Avatars/UserAvatar.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { capitalizeFirstLetter } from "../../utils/textUtils.js";

export function DashboardOverview() {
  const { user, customer, serviceProvider } = useActiveStates();

  const greetingName = user
    ? user.nickname || user.given_name || "Hello"
    : "Guest";

  const icons = {
    up: IconArrowUpRight,
    down: IconArrowDownRight,
  };

  const data = [
    {
      label: "Page views",
      stats: "456,578",
      progress: 65,
      color: "teal",
      icon: "up",
    },
    {
      label: "New users",
      stats: "2,550",
      progress: 72,
      color: "blue",
      icon: "up",
    },
    {
      label: "Orders",
      stats: "4,735",
      progress: 52,
      color: "red",
      icon: "down",
    },
  ] as const;

  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];
    return (
      <Paper withBorder radius="md" p="xs" key={stat.label}>
        <Group>
          <RingProgress
            size={80}
            roundCaps
            thickness={8}
            sections={[{ value: stat.progress, color: stat.color }]}
            label={
              <Center>
                <Icon size={20} stroke={1.5} />
              </Center>
            }
          />

          <div>
            <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
              {stat.label}
            </Text>
            <Text fw={700} size="xl">
              {stat.stats}
            </Text>
          </div>
        </Group>
      </Paper>
    );
  });

  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />

      <Stack gap={10} p="xl">
        <Box p="md">
          <Group>
            <UserAvatar size={55} radius="xl" user={user} />
            <Stack>
              <Title order={2}>
                Good morning,{" "}
                <Text component="span" inherit>
                  {capitalizeFirstLetter(greetingName)}!
                </Text>
                <Text size="sm" c="dimmed">
                  Here's your overview for{" "}
                  {customer?.name || serviceProvider?.name}
                </Text>
              </Title>
            </Stack>
          </Group>
        </Box>
        <Box p="md">
          <SimpleGrid cols={{ base: 1, sm: 3 }}>{stats}</SimpleGrid>
        </Box>
      </Stack>
    </Layout>
  );
}
