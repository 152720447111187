import {
  ActionIcon,
  Group,
  Menu,
  ScrollArea,
  Table,
  Text,
} from "@mantine/core";
import { IconDots, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { UserEntity } from "../../../common/entities/user.js";
import { UserAvatar } from "../Avatars/UserAvatar.js";
import { UserModal } from "../Modals/UserModal.js";

interface UsersTableProps {
  users: UserEntity[];
  onDelete?: (user: UserEntity) => void;
  onEdit?: (user: UserEntity) => void;
}

export function UsersTable({ users, onDelete, onEdit }: UsersTableProps) {
  const [editingUser, setEditingUser] = useState<UserEntity | null>(null);
  const [modalOpened, setModalOpened] = useState(false);

  const handleEdit = (user: UserEntity) => {
    setEditingUser(user);
    setModalOpened(true);
  };

  const handleEditSubmit = async (updatedUser: Partial<UserEntity>) => {
    if (onEdit && editingUser) {
      await onEdit({ ...editingUser, ...updatedUser });
    }
    setModalOpened(false);
    setEditingUser(null);
  };

  const rows = users.map((user) => (
    <Table.Tr key={user.id}>
      <Table.Td pl="lg">
        <Group gap="sm">
          <UserAvatar user={user} size={45} radius={80} />
          <div>
            <Text fw={700}>
              {" "}
              {user.given_name} {user.family_name}
            </Text>
            <Text fz="sm" c="dimmed">
              Member since{" "}
              {new Date(user.created_at ?? Date.now()).toLocaleDateString(
                undefined,
                {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                }
              )}
            </Text>
          </div>
        </Group>
      </Table.Td>
      <Table.Td>
        {/* <Badge color={user.active ? "green" : "gray"}>
          {user.active ? "Active" : "Inactive"}
        </Badge> */}
      </Table.Td>
      <Table.Td>{user.email}</Table.Td>
      <Table.Td>
        <Menu position="bottom-end" withArrow>
          <Menu.Target>
            <ActionIcon variant="subtle" color="gray">
              <IconDots style={{ width: "70%", height: "70%" }} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              leftSection={<IconPencil size={14} />}
              onClick={() => handleEdit(user)}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              leftSection={<IconTrash size={14} />}
              color="red"
              onClick={() => onDelete?.(user)}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <ScrollArea h={`calc(100vh - 140px)`}>
        <Table verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th pl="lg">User</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th>Phone</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </ScrollArea>

      <UserModal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setEditingUser(null);
        }}
        onSubmit={handleEditSubmit}
        editMode={true}
        initialData={editingUser ?? undefined}
      />
    </>
  );
}
