import { TimestampField } from "../types/field";

export const timestampField: TimestampField<{
  name: "timestamp";
  required: true;
}> = {
  label: "Timestamp",
  name: "timestamp",
  required: true,
  type: "timestamp",
};
