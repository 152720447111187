import { IntegerField } from "../types/field";

/**
 * Message library for the platform.
 * Default swaps are provided for each message, so that the message can be customized to the user and item.
 * The default swaps are:
 * - 'First name' .......... becomes the user's first name
 * - 'Last name' ........... becomes the user's last name
 * - 'Nickname' ............ becomes the user's nickname
 * - 'This user' ........... becomes the user's full name
 * - 'This item' ........... becomes the item's name
 * - 'This organization' ... becomes the organization's name
 */
export const MESSAGE_LIBRARY: Record<number, string> = {
  0: "Greetings, how can I help you today?",
  1: "Hello, how can I help you today?",
  2: "I'm sorry, I don't understand. Can you please rephrase your question?",
  3: "Thank you for contacting us. We will get back to you soon.",
  4: "Are you sure you want to delete this item?",
  5: "This item has been deleted.",
  6: "This item has been updated.",
  7: "This item has been created.",
  8: "This item has been retrieved.",
  9: "This user has signed in to this organization.",
};

// todo: custom language when viewing self events (?)

export const MESSAGE_LIBRARY_ID_GREETINGS = 0 as const;
export const MESSAGE_LIBRARY_ID_HELLO = 1 as const;
export const MESSAGE_LIBRARY_ID_UNRECOGNIZED = 2 as const;
export const MESSAGE_LIBRARY_ID_THANKS = 3 as const;
export const MESSAGE_LIBRARY_ID_DELETE_CONFIRM = 4 as const;
export const MESSAGE_LIBRARY_ID_DELETE_SUCCESS = 5 as const;
export const MESSAGE_LIBRARY_ID_UPDATE_SUCCESS = 6 as const;
export const MESSAGE_LIBRARY_ID_CREATE_SUCCESS = 7 as const;
export const MESSAGE_LIBRARY_ID_RETRIEVE_SUCCESS = 8 as const;

export const MESSAGE_LIBRARY_ID_USER_SIGN_IN = 9 as const;

export type MessageType =
  | typeof MESSAGE_LIBRARY_ID_GREETINGS
  | typeof MESSAGE_LIBRARY_ID_HELLO
  | typeof MESSAGE_LIBRARY_ID_UNRECOGNIZED
  | typeof MESSAGE_LIBRARY_ID_THANKS
  | typeof MESSAGE_LIBRARY_ID_DELETE_CONFIRM
  | typeof MESSAGE_LIBRARY_ID_DELETE_SUCCESS
  | typeof MESSAGE_LIBRARY_ID_UPDATE_SUCCESS
  | typeof MESSAGE_LIBRARY_ID_CREATE_SUCCESS
  | typeof MESSAGE_LIBRARY_ID_RETRIEVE_SUCCESS
  | typeof MESSAGE_LIBRARY_ID_USER_SIGN_IN;

export const messageField: IntegerField<{
  final: true;
  name: "message";
  required: true;
}> = {
  label: "Message",
  final: true,
  name: "message",
  required: true,
  type: "integer",
};
