import { IntegerField } from "../types/field";

export const userIdField: IntegerField<{
  final: true;
  name: "user_id";
  required: true;
}> = {
  final: true,
  label: "User ID",
  name: "user_id",
  required: true,
  type: "integer",
};
