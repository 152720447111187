import { TimestampField } from "../types/field";

export const archivedAtField: TimestampField<{
  auto: true;
  name: "archived_at";
}> = {
  auto: true,
  label: "Archived At",
  name: "archived_at",
  type: "timestamp",
};
