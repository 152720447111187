import { Button, Paper, Text, Title } from "@mantine/core";
import { IconClock } from "@tabler/icons-react";

import classes from "./ArticleCardImage.css.js";

import { RecipeButton } from "../Buttons/RecipeButton.js";

interface ArticleCardImageProps {
  backgroundColor?: string;
  backgroundImage?: string;
  button?: JSX.Element | null;
  category?: string;
  comingsoon: boolean;
  id: number;
  link?: string;
  logo?: string;
  scope?: string;
  title: string;
}

export const ArticleCardImage = ({
  backgroundColor,
  backgroundImage,
  button,
  category,
  comingsoon,
  id,
  logo,
  scope,
  title,
}: ArticleCardImageProps) => {
  const style = {
    ...(backgroundImage && { backgroundImage: `url(${backgroundImage})` }),
    ...(backgroundColor && { backgroundColor }),
    ...(comingsoon && { opacity: "0.5" }),
  };

  return (
    <Paper
      shadow="lg"
      withBorder
      p="xl"
      radius="xs"
      className={classes.card}
      style={style}
    >
      <div>
        {!logo ? (
          <>
            <Text className={classes.category} size="xs">
              {category}
            </Text>
            <Title order={3} className={classes.title}>
              {title}
            </Title>
          </>
        ) : (
          <img alt="logo" src={logo} width={150} height={70} />
        )}
      </div>
      <Text c="white">{scope}</Text>
      {button ? (
        button
      ) : !comingsoon ? (
        <RecipeButton id={id} />
      ) : (
        <Button variant="subtle" color="white" leftSection={<IconClock />}>
          Coming soon
        </Button>
      )}
    </Paper>
  );
};
