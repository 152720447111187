import { Anchor, Text, Title, TitleOrder } from "@mantine/core";
import { forwardRef } from "react";
import ReactMarkdown from "react-markdown";

type MarkdownProps = {
  content: string;
};

type HeadingProps = {
  component: "h1" | "h2" | "h3";
  order?: TitleOrder;
  children?: React.ReactNode;
};

type TextProps = {
  size: string;
  children?: React.ReactNode;
};

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ component, order, children, ...props }, ref) => (
    <Title component={component} order={order} ref={ref} {...props}>
      {children}
    </Title>
  )
);

const RegularText = forwardRef<HTMLHeadingElement, TextProps>(
  ({ size, children, ...props }, ref) => (
    <Text size={size} ref={ref} {...props}>
      {children}
    </Text>
  )
);

const LinkRenderer = (props: { href?: string; children?: React.ReactNode }) => (
  <Anchor
    href={props?.href}
    underline="always"
    target="_blank"
    rel="noopener noreferrer"
  >
    {props?.children}
  </Anchor>
);

export function MarkdownRenderer({ content }: MarkdownProps) {
  return (
    <ReactMarkdown
      components={{
        h1: (props) => <Heading component="h1" order={1} {...props} />,
        h2: (props) => <Heading component="h2" order={2} {...props} />,
        h3: (props) => <Heading component="h3" order={3} {...props} />,
        p: (props) => <RegularText size="md" {...props} />,
        a: LinkRenderer, // Custom link renderer
      }}
    >
      {content}
    </ReactMarkdown>
  );
}
