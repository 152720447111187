import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useChat } from "@ai-sdk/react";
import { ChatInput } from "./ChatInput.js";
import { Box, rem } from "@mantine/core";
import { useRef, useEffect } from "react";
import { MessageDisplay } from "./MessageDisplay.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";

export function PennyOverview() {
  const { user, serviceProvider, customer } = useActiveStates();
  const organization = customer || serviceProvider;

  // Add function to get time-based greeting
  const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 17) return "Good afternoon";
    return "Good evening";
  };

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    isLoading,
    addToolResult,
  } = useChat({
    api: "/api/chat",
    body: {
      user: user,
      organization: organization,
    },
    onResponse: () => {
      console.log("Overview - Message being sent:", input);
    },
    initialMessages: [
      {
        id: "welcome-message",
        role: "assistant",
        content: `${getTimeBasedGreeting()} ${
          user?.given_name || "there"
        }! 👋 I'm Penny, your AI assistant${
          organization ? ` for ${organization.name}` : ""
        }. I can help you with various tasks, answer questions, and provide assistance. Feel free to ask me anything!`,
      },
    ],
  });

  // Reference to the chat display container
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Function to scroll to bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const scrollElement = chatContainerRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  };

  // Scroll when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Add a small delay to ensure content is rendered
  useEffect(() => {
    const timeoutId = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timeoutId);
  }, [messages]);

  return (
    <Layout>
      <PageTitle title="New Chat" />

      <Box
        style={{
          height: `calc(100vh - ${rem(70)})`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          p="xl"
          ref={chatContainerRef}
          style={{
            flex: 1,
            overflowY: "auto",
            alignContent: "flex-end",
          }}
        >
          {messages.map((message) => (
            <MessageDisplay
              message={message}
              key={message.id}
              addToolResult={(toolCallId, result) =>
                addToolResult({ toolCallId, result })
              }
            />
          ))}
        </Box>
        <Box
          p="md"
          style={{
            height: "150px",
            borderTop: "1px solid var(--app-shell-border-color)",
          }}
        >
          <ChatInput
            input={input}
            setInput={handleInputChange}
            onSend={handleSubmit}
            loading={isLoading}
          />
        </Box>
      </Box>
    </Layout>
  );
}
