import { IntegerField } from "../types/field";

export const virtualOrganizationIdField: IntegerField<{
  name: "organization_id";
  virtual: true;
}> = {
  label: "Organization ID",
  name: "organization_id",
  virtual: true,
  type: "integer",
};
