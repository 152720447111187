import { Message } from "@ai-sdk/react";
import { Code, Stack, Tabs, Text } from "@mantine/core";
import { useState } from "react";

import { MarkdownRenderer } from "./MarkdownRenderer.js";

import classes from "./Chat.css.js";

type ChatDisplayProps = {
  message: Message;
  addToolResult: (toolCallId: string, result: string) => void;
};

interface GuruResult {
  answer: string;
  cards: Array<{
    id: string;
    title: string;
    content: string;
    url: string;
  }>;
}

interface ToolInvocation {
  toolName: string;
  toolCallId: string;
  state: "call" | "result" | "error" | "partial-call";
  args?: Record<string, unknown>;
  result?: string;
  error?: string;
}

export function MessageDisplay({ message, addToolResult }: ChatDisplayProps) {
  const [isCodeExpanded, setIsCodeExpanded] = useState(false);

  // Helper function to parse calculator result
  const parseCalculatorResult = (result: string | undefined) => {
    if (!result) return { answer: "No result", steps: "" };

    const [answer, ...steps] = result.split("\n");
    return {
      answer,
      steps: steps.join("\n"),
    };
  };

  // Helper function to parse Guru result
  const parseGuruResult = (result: string): GuruResult => {
    try {
      return JSON.parse(result);
    } catch {
      return { answer: result, cards: [] };
    }
  };

  const renderToolInvocation = (invocation: ToolInvocation) => {
    switch (invocation.toolName) {
      case "askForConfirmation":
        return (
          <div key={invocation.toolCallId}>
            {String(invocation.args?.message)}
            <div>
              {invocation.state === "result" &&
              invocation.result === "Waiting for user confirmation..." ? (
                <>
                  <button
                    onClick={() => addToolResult(invocation.toolCallId, "Yes")}
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => addToolResult(invocation.toolCallId, "No")}
                  >
                    No
                  </button>
                </>
              ) : (
                <b>{invocation.result}</b>
              )}
            </div>
          </div>
        );

      case "guruSearch":
        if (invocation.state === "result") {
          const { answer, cards } = parseGuruResult(invocation.result ?? "");
          return (
            <div key={invocation.toolCallId} className="mt-4 space-y-2">
              <div className="rounded-lg bg-gray-50 p-4 shadow-sm">
                {cards && cards.length > 0 ? (
                  <div className="mt-4">
                    <Tabs defaultValue="answer">
                      <Tabs.List>
                        <Tabs.Tab value="answer">Answer</Tabs.Tab>
                        {cards.map((card) => (
                          <Tabs.Tab key={card.id} value={card.id}>
                            Source: {card.title || "Source Document"}
                          </Tabs.Tab>
                        ))}
                      </Tabs.List>

                      <Tabs.Panel value="answer" p="md">
                        <MarkdownRenderer content={answer} />
                      </Tabs.Panel>

                      {cards.map((card) => (
                        <Tabs.Panel key={card.id} value={card.id} p="md">
                          <Stack>
                            <Text size="lg" fw={700}>
                              {card.title}
                            </Text>
                            <div
                              dangerouslySetInnerHTML={{ __html: card.content }}
                              className="guru-content"
                            />
                          </Stack>
                        </Tabs.Panel>
                      ))}
                    </Tabs>
                  </div>
                ) : (
                  <MarkdownRenderer content={answer} />
                )}
              </div>
            </div>
          );
        }
        if (invocation.state === "call") {
          return (
            <div key={invocation.toolCallId} className="text-sm text-gray-500">
              🔍 Searching internal knowledge base...
            </div>
          );
        }
        if (invocation.state === "error") {
          return (
            <div
              key={invocation.toolCallId}
              className="text-sm text-red-500 p-2 bg-red-50 rounded"
            >
              ❌ Error searching Guru: {invocation.error}
            </div>
          );
        }
        break;

      case "calculator": {
        if (invocation.state !== "result") return null;
        const { answer, steps } = parseCalculatorResult(invocation.result);
        return (
          <Tabs defaultValue="answer" key={invocation.toolCallId}>
            <Tabs.List>
              <Tabs.Tab value="answer">Answer</Tabs.Tab>
              {steps && <Tabs.Tab value="steps">How I Solved It</Tabs.Tab>}
            </Tabs.List>

            <Tabs.Panel value="answer" p="sm">
              <div className={classes.toolResult}>
                <Text size="lg" fw={700} c="blue">
                  {answer}
                </Text>
              </div>
            </Tabs.Panel>

            {steps && (
              <Tabs.Panel value="steps" p="sm">
                <Stack className={classes.toolResult} gap="xs">
                  {steps.split("\n\n").map((step, index) => {
                    if (step.startsWith("Starting with:")) {
                      return (
                        <Text key={index} c="dimmed" fs="italic">
                          {step}
                        </Text>
                      );
                    }
                    if (step.startsWith("Expression is now:")) {
                      return (
                        <Text key={index} c="blue" fw={500}>
                          {step}
                        </Text>
                      );
                    }
                    if (step.startsWith("Solve")) {
                      const [operation, calculation] = step.split("\n");
                      return (
                        <div key={index}>
                          <Text size="sm" fw={500}>
                            {operation}
                          </Text>
                          <Code ml="md" style={{ fontSize: "1.1em" }}>
                            {calculation}
                          </Code>
                        </div>
                      );
                    }
                    return (
                      <Text key={index} size="sm">
                        {step}
                      </Text>
                    );
                  })}
                  <div
                    style={{
                      marginTop: "var(--mantine-spacing-md)",
                      borderTop: "1px solid var(--mantine-color-gray-3)",
                      paddingTop: "var(--mantine-spacing-md)",
                    }}
                  >
                    <Text size="lg" fw={700} c="blue">
                      Final Answer: {answer}
                    </Text>
                  </div>
                </Stack>
              </Tabs.Panel>
            )}
          </Tabs>
        );
      }
      default:
        return invocation.state === "result" ? (
          <MarkdownRenderer
            key={invocation.toolCallId}
            content={invocation.result ?? invocation.toolName}
          />
        ) : (
          "Thinking..."
        );
    }
  };

  return (
    <div className={classes.chatWrapperInner}>
      <div
        className={
          message.role === "user" ? classes.userBubble : classes.botBubble
        }
      >
        {message.content ? (
          <MarkdownRenderer content={message.content} />
        ) : null}
        {message.toolInvocations?.map(renderToolInvocation)}

        <div
          style={{ cursor: "pointer" }}
          onClick={() => setIsCodeExpanded(!isCodeExpanded)}
        >
          <Text size="sm" c="dimmed">
            {isCodeExpanded ? "▼ Hide Debug Info" : "▶ Show Debug Info"}
          </Text>
          {isCodeExpanded && (
            <pre
              style={{
                overflow: "auto",
                display: "block",
                fontSize: "10px",
                whiteSpace: "pre-wrap",
              }}
            >
              {JSON.stringify(message, null, 2)}
            </pre>
          )}
        </div>
      </div>
    </div>
  );
}
