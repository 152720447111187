import { useCallback, useState } from "react";

export function useKeyedState<T>(
  key: string,
  initialValue: T
): [T, (newValue: T) => void] {
  const [keyedState, setKeyedState] = useState<Record<string, T>>({});

  const updateKeyedState = useCallback(
    (newValue: T) => {
      setKeyedState((all) => ({ ...all, [key]: newValue }));
    },
    [key, setKeyedState]
  );

  return [keyedState[key] ?? initialValue, updateKeyedState];
}
