import {
  ActionIcon,
  AppShell,
  Box,
  Button,
  Card,
  Divider,
  Group,
  Indicator,
  Loader,
  Tabs,
  Title,
} from "@mantine/core";
import { IconChecks, IconRefresh } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import { MessageEntity } from "../../../common/entities/message.js";
import { UserEntity } from "../../../common/entities/user.js";
import { ComponentError } from "../../components/Errors/ComponentError.js";
import { MessageTicket } from "../../components/Messages/MessageTicket.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { MessageControl } from "../../hooks/useMessages.js";

import classes from "./Notifications.css.js";

function MarkAsReadButton({
  message,
  messageControl,
  user,
}: {
  message: MessageEntity;
  messageControl: MessageControl;
  user: UserEntity;
}) {
  const messageIsRead =
    messageControl.readTimestamp >= new Date(message.timestamp ?? 0).getTime();
  return messageIsRead ? (
    <Button
      size="xs"
      disabled={!messageIsRead || messageControl.markingAsRead}
      title="Mark as Unread"
      variant="outline"
      onClick={(e) => {
        e.stopPropagation();
        if (typeof user?.id === "number" && message) {
          messageControl.markAsRead(message, user.id, true);
        }
      }}
    >
      Mark as unread
    </Button>
  ) : (
    <Button
      size="xs"
      disabled={messageIsRead || messageControl.markingAsRead}
      title="Mark as Read"
      variant="primary"
      onClick={(e) => {
        e.stopPropagation();
        if (typeof user?.id === "number" && message) {
          messageControl.markAsRead(message, user.id);
        }
      }}
    >
      Mark as read
    </Button>
  );
}

export function NotificationsOverview({
  isAllActivity = false,
  recentActivityOnly = false,
}: {
  isAllActivity?: boolean;
  recentActivityOnly?: boolean;
}) {
  const navigate = useNavigate();
  const { messageControl, user, customer, serviceProvider } = useActiveStates();

  const notificationsButtons = (
    <Group justify="space-between">
      <Button
        size="sm"
        variant="outline"
        title={
          messageControl.activeMessageIndex === 0
            ? "No newer message"
            : "Newer message"
        }
        leftSection={<IconChecks />}
        disabled={messageControl.loadingMessages}
        onClick={() => {
          if (user?.id) {
            messageControl.loadMessages(
              user.id,
              serviceProvider?.id,
              customer?.id
            );
          }
        }}
      >
        Mark all as read
      </Button>
      <Button
        size="sm"
        variant="primary"
        title={
          messageControl.activeMessageIndex ===
          messageControl.messages.length - 1
            ? "No older message"
            : "Older message"
        }
        onClick={() => navigate("/activity")}
      >
        View all activity
      </Button>
    </Group>
  );

  const notifications = (
    <>
      {messageControl.messages.map((message) => {
        const isRead =
          messageControl.readTimestamp >=
          new Date(message.timestamp ?? 0).getTime();
        return (
          <div key={message.id}>
            <Divider />
            <Card>
              <Group
                align="flex-start"
                justify="space-between"
                key={message.id}
                onClick={() => messageControl.focusMessage(message)}
              >
                <Box w="calc(100% - 40px)">
                  <MessageTicket
                    message={message}
                    focused={message.id === messageControl.activeMessage?.id}
                    read={isRead}
                  />
                  {!isAllActivity && (
                    <MarkAsReadButton
                      message={message}
                      messageControl={messageControl}
                      user={user}
                    />
                  )}
                </Box>
                {isRead || isAllActivity ? null : <Indicator mr={10} mt={10} />}
              </Group>
            </Card>
          </div>
        );
      })}
    </>
  );

  return (
    <>
      <Title order={5}>Activity</Title>
      <Tabs defaultValue="all">
        <Tabs.List>
          <Tabs.Tab value="all">All</Tabs.Tab>
          <Tabs.Tab value="vip">VIP</Tabs.Tab>
          <Tabs.Tab value="mentions">Mentions</Tabs.Tab>
          <Tabs.Tab value="threads">Threads</Tabs.Tab>
          <Tabs.Tab value="reactions">Reactions</Tabs.Tab>
          <Tabs.Tab value="invitations">Invitations</Tabs.Tab>
          <Tabs.Tab value="apps">Apps</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="all">
          {Array.isArray(messageControl.loadMessagesError) ? (
            <ComponentError
              title="Can't load messages"
              messages={messageControl.loadMessagesError}
            />
          ) : messageControl.loadingMessages ? (
            <AppShell.Section
              grow
              ta="left"
              style={{
                alignContent: "flex-end",
                alignItems: "center",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                overflowY: "auto",
              }}
            >
              <Loader />
            </AppShell.Section>
          ) : (
            <AppShell.Section
              grow
              ta="left"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "flex-end",
                overflowY: "auto",
              }}
            >
              {notifications}
            </AppShell.Section>
          )}
        </Tabs.Panel>

        <Tabs.Panel value="vip">
          {/* Content for VIP tab */}
          VIP tab content
        </Tabs.Panel>

        <Tabs.Panel value="mentions">
          {/* Content for Mentions tab */}
          Mentions tab content
        </Tabs.Panel>

        <Tabs.Panel value="threads">
          {/* Content for Threads tab */}
          Threads tab content
        </Tabs.Panel>

        <Tabs.Panel value="reactions">
          {/* Content for Reactions tab */}
          Reactions tab content
        </Tabs.Panel>

        <Tabs.Panel value="invitations">
          {/* Content for Invitations tab */}
          Invitations tab content
        </Tabs.Panel>

        <Tabs.Panel value="apps">
          {/* Content for Apps tab */}
          Apps tab content
        </Tabs.Panel>
      </Tabs>
      <ActionIcon
        variant="outline"
        disabled={messageControl.loadingMessages}
        onClick={() => {
          if (user?.id) {
            messageControl.loadMessages(
              user.id,
              serviceProvider?.id,
              customer?.id
            );
          }
        }}
      >
        <IconRefresh stroke={2} size={16} />
      </ActionIcon>

      {!isAllActivity && (
        <AppShell.Section
          p="md"
          pt="lg"
          className={classes.sectionAsideFooterBorder}
        >
          {notificationsButtons}
        </AppShell.Section>
      )}
    </>
  );
}
