import { capitalizeFirstLetter } from "../../app/utils/textUtils";
import { MESSAGE_LIBRARY } from "../fields/message";
import { Entity } from "../types/entity";
import { OrganizationEntity } from "./organization";
import { getUserName, UserEntity } from "./user";

export interface Swap {
  replace: string;
  titleCaseSearch: string;
}

export interface ReactSwap {
  replace: React.ReactNode;
  titleCaseSearch: string;
}

/**
 * Replaces a search string with a correctly-capitalized version of the replace string.
 * @param text - The text to replace.
 * @param titleCaseSearch - The string to search for.
 * @param replace - The string to replace the search string with.
 * @returns The text with the search string replaced with the correctly-capitalized replace string.
 * Be sure to pass in titleCaseSearch as a Title Case string, so that it can be matched as caseSensitive.
 * Be sure to pass in replace as a lowercase string, so that it can be Capitalized when matching the titleCaseSearch.as caseSensitive
 * Algorithm:
 * 1. Replace all instances of titleCaseSearch with the capitalized version of replace.
 * 2. Replace all instances of case-insensitive titleCaseSearch with the unmodifi version of replace.
 */
export function swapItem(text: string, swap: Swap) {
  return text
    .replace(new RegExp(swap.titleCaseSearch, "g"), () =>
      capitalizeFirstLetter(swap.replace)
    )
    .replace(new RegExp(swap.titleCaseSearch, "gi"), () => swap.replace);
}

export function getMessageLibraryItem(id: number, swaps: Swap[]): string {
  if (!(id in MESSAGE_LIBRARY)) {
    throw new Error(`Message library item ${id} not found`);
  }
  if (!swaps || swaps.length === 0) {
    return MESSAGE_LIBRARY[id];
  }
  const formattedMessage = swaps.reduce(
    (acc, swap) => swapItem(acc, swap),
    MESSAGE_LIBRARY[id]
  );
  return formattedMessage;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function defaultMessageSwaps<T extends Entity<any, any>>(
  user: UserEntity,
  organization?: OrganizationEntity,
  ref0?: T
): Swap[] {
  return [
    { replace: user.given_name, titleCaseSearch: "First name" },
    { replace: user.family_name, titleCaseSearch: "Last name" },
    { replace: user.nickname, titleCaseSearch: "Nickname" },
    { replace: getUserName(user), titleCaseSearch: "This user" },
    ...(typeof ref0 === "object"
      ? [{ replace: ref0.name, titleCaseSearch: "This item" }]
      : []),
    ...(typeof organization === "object"
      ? [{ replace: organization.name, titleCaseSearch: "This organization" }]
      : []),
  ];
}

export function defaultMessageSwapsReact<T = void>(
  user: UserEntity,
  userLink: React.ReactNode,
  organization?: OrganizationEntity,
  organizationLink?: React.ReactNode,
  ref0?: T,
  ref0Link?: React.ReactNode
): ReactSwap[] {
  return [
    { replace: user.given_name, titleCaseSearch: "First name" },
    { replace: user.family_name, titleCaseSearch: "Last name" },
    { replace: user.nickname, titleCaseSearch: "Nickname" },
    {
      replace: userLink,
      titleCaseSearch: "This user",
    },
    ...(typeof ref0 === "object"
      ? [{ replace: ref0Link, titleCaseSearch: "This item" }]
      : []),
    ...(typeof organization === "object"
      ? [{ replace: organizationLink, titleCaseSearch: "This organization" }]
      : []),
  ];
}

export function messageWithDefaultSwaps(
  messageId: number,
  user: UserEntity,
  organization: OrganizationEntity,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref0?: Entity<any, any>
) {
  return getMessageLibraryItem(
    messageId,
    defaultMessageSwaps(user, organization, ref0)
  );
}

export function getMessageLibraryItemWithSwapsReact(
  messageId: number,
  swaps: ReactSwap[]
): React.ReactNode[] {
  if (!(messageId in MESSAGE_LIBRARY)) {
    throw new Error(`Message library item ${messageId} not found`);
  }
  if (!swaps || swaps.length === 0) {
    return [MESSAGE_LIBRARY[messageId]];
  }
  const formattedMessage = swaps.reduce(
    (acc, swap) => swapItemReact(acc, swap),
    [MESSAGE_LIBRARY[messageId]]
  );
  return formattedMessage;
}

function swapItemReact(text: (string | React.ReactNode)[], swap: ReactSwap) {
  const output: React.ReactNode[] = [];
  for (const segment of text) {
    if (typeof segment === "string") {
      const match = segment.match(new RegExp(swap.titleCaseSearch, "i"));
      if (match) {
        const index = segment.indexOf(match[0]);
        output.push(segment.slice(0, index));
        output.push(swap.replace);
        if (typeof swap.replace === "object" && "props" in swap.replace) {
          swap.replace.props.key = swap.titleCaseSearch;
        }
        output.push(segment.slice(index + match[0].length));
      } else {
        output.push(segment);
      }
    } else {
      output.push(segment);
    }
  }
  return output;
}
