import {
  ActionIcon,
  Indicator,
  Menu,
  rem,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconBell,
  IconBellFilled,
  IconBuilding,
  IconDots,
  IconEdit,
  IconHome,
  IconHomeFilled,
  IconLink,
  IconPlayerPlay,
  IconUserSquare,
} from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import classes from "./Navbar.css.js";

import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { ServiceProviderMenu } from "../Menus/ServiceProviderMenu.js";

export interface Sublinks {
  [key: string]: { label: string; href: string }[];
}

export function Navbar() {
  const { messageControl } = useActiveStates();

  const location = useLocation();
  const [active, setActive] = useState("");

  const mainLinksData = useMemo(() => {
    return [
      {
        icon: IconHome,
        iconFilled: IconHomeFilled,
        label: "Dashboard",
        href: "/dashboard",
        indicator: messageControl.unreadCount > 0,
        indicatorLabel: messageControl.unreadCount?.toString?.(10) ?? "",
      },
      {
        icon: IconBell,
        iconFilled: IconBellFilled,
        label: "Activity",
        href: "/activity",
        indicator: messageControl.unreadCount > 0,
        indicatorLabel: messageControl.unreadCount?.toString?.(10) ?? "",
      },
      {
        icon: IconDots,
        iconFilled: IconDots,
        label: "More",
        href: "/activity",
      },
    ];
  }, [messageControl.unreadCount]);

  useEffect(() => {
    const path = location.pathname;

    const mainLink = mainLinksData.find((link) => {
      if (link.href === "/") {
        return path === "/";
      } else {
        return path.startsWith(link.href);
      }
    });

    if (mainLink) {
      setActive(mainLink.label);
    } else {
      setActive("");
    }
  }, [location.pathname, mainLinksData]);

  const navigate = useNavigate();

  const mainLinks = mainLinksData.map((link) => {
    const IconComponent = link.label === active ? link.iconFilled : link.icon;

    if (link.label === "More") {
      return (
        <Menu key={link.label} withArrow shadow="md" position="right">
          <Menu.Target>
            <ActionIcon
              variant={link.label === active ? "light" : "default"}
              className={classes.mainLink}
              data-active={link.label === active || undefined}
            >
              <IconComponent
                style={{ width: rem(27), height: rem(27) }}
                stroke={2}
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => navigate("/recipes/overview")}
              leftSection={
                <IconPlayerPlay style={{ width: rem(27), height: rem(27) }} />
              }
            >
              <Text fw={500} size="sm">
                Automations
              </Text>
              <Text size="xs">Create and find workflows</Text>
            </Menu.Item>
            <Menu.Item
              onClick={() => navigate("/connect/overview")}
              leftSection={
                <IconLink style={{ width: rem(27), height: rem(27) }} />
              }
            >
              <Text fw={500} size="sm">
                Apps & Integrations
              </Text>
              <Text size="xs">
                Access knowledge and data from external sources
              </Text>
            </Menu.Item>
            <Menu.Item
              onClick={() => navigate("/clients/overview")}
              leftSection={
                <IconBuilding style={{ width: rem(27), height: rem(27) }} />
              }
            >
              <Text fw={500} size="sm">
                Clients
              </Text>
              <Text size="xs">Your clients and their settings</Text>
            </Menu.Item>
            <Menu.Item
              onClick={() => navigate("/people/overview")}
              leftSection={
                <IconUserSquare style={{ width: rem(27), height: rem(27) }} />
              }
            >
              <Text fw={500} size="sm">
                People
              </Text>
              <Text size="xs">Your team and their settings</Text>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <Tooltip
        label={link.label}
        position="right"
        withArrow
        transitionProps={{ duration: 0 }}
        key={link.label}
      >
        <ActionIcon
          variant={link.label === active ? "filled" : "default"}
          onClick={() => {
            setActive(link.label);
            navigate(link.href);
          }}
          className={classes.mainLink}
          data-active={link.label === active || undefined}
        >
          {link.indicator ? (
            <Indicator
              inline
              size={7}
              offset={3}
              position="top-end"
              color="red"
            >
              <IconComponent
                style={{ width: rem(27), height: rem(27) }}
                stroke={2}
              />
            </Indicator>
          ) : (
            <IconComponent
              style={{ width: rem(27), height: rem(27) }}
              stroke={2}
            />
          )}
        </ActionIcon>
      </Tooltip>
    );
  });

  return (
    <nav className={classes.navbar}>
      <div className={classes.wrapper}>
        <div className={classes.aside}>
          <Stack justify="flex-start" gap={10} className={classes.mainLinks}>
            {mainLinks}
          </Stack>
          <Stack justify="flex-end" gap={15}>
            <ActionIcon
              variant="default"
              style={{
                width: "calc(2.8125rem * var(--mantine-scale))",
                height: "calc(2.8125rem * var(--mantine-scale))",
                borderRadius: "var(--mantine-radius-xl)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "0",
              }}
              onClick={() => {
                navigate("/penny/overview");
              }}
            >
              <IconEdit
                style={{ width: rem(27), height: rem(27) }}
                stroke={2}
              />
            </ActionIcon>
            <ServiceProviderMenu />
          </Stack>
        </div>
      </div>
    </nav>
  );
}
