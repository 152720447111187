import { IntegerField } from "../types/field";

export const organizationIdField: IntegerField<{
  final: true;
  name: "organization_id";
  required: true;
}> = {
  final: true,
  label: "Organization ID",
  name: "organization_id",
  required: true,
  type: "integer",
};
