import { BooleanField } from "../types/field";

export const isOrganizationOwnerField: BooleanField<{
  name: "is_organization_owner";
  required: true;
}> = {
  label: "Is Organization Owner",
  name: "is_organization_owner",
  required: true,
  type: "boolean",
};
