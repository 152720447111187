type Primitive = string | number;

interface RequestOptions extends RequestInit {
  data?: object;
  params?: Record<string, Primitive | undefined>;
}

function urlWithParams(
  url: string,
  params?: Record<string, Primitive | undefined>
) {
  const fake = "http://_";
  const isLocalUrl = url.startsWith("/");
  const fullUrl = isLocalUrl ? `${fake}${url}` : url;
  const finalUrl = new URL(fullUrl);
  if (params) {
    Object.entries(params).forEach(([k, v]) => {
      if (typeof v !== "undefined") {
        finalUrl.searchParams.append(
          k,
          typeof v === "number"
            ? v.toString(10)
            : typeof v === "string"
            ? v
            : String(v)
        );
      }
    });
  }
  const finalUrlPath = finalUrl.toString();
  return isLocalUrl ? finalUrlPath.replace(fake, "") : finalUrlPath;
}

interface NetworkResponseSuccess<TResponseData> {
  data: TResponseData;
  status: number;
  success: true;
}

interface NetworkResponseError {
  error: string;
  text: string;
  status: number;
  success: false;
}

export type NetworkResponse<TResponseData> =
  | NetworkResponseError
  | NetworkResponseSuccess<TResponseData>;

async function resolve<TResponseData>(
  response: Response,
  onError: (error: string) => void
): Promise<NetworkResponse<TResponseData>> {
  try {
    if (response.status < 200 || response.status >= 300) {
      onError(response.statusText);
      return {
        error: response.statusText,
        text: await response.text(),
        status: response.status,
        success: false,
      };
    }
    return {
      data: await response.json(),
      status: response.status,
      success: true,
    };
  } catch (e) {
    onError(e?.message ?? "Unknown error");
    return {
      error: e,
      text: await response.text(),
      status: response.status,
      success: false,
    };
  }
}

export const networkOnce = {
  async get<TResponseData>(
    url: string,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), init),
      onError
    );
  },
  async delete<TResponseData>(
    url: string,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, data, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: data ? JSON.stringify(data) : undefined,
        method: "DELETE",
      }),
      onError
    );
  },
  async post<TResponseData>(
    url: string,
    body: object,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: JSON.stringify(body),
        method: "POST",
      }),
      onError
    );
  },
  async postFormData<TResponseData>(
    url: string,
    body: Record<string, Primitive | undefined>,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: new URLSearchParams(body as Record<string, string>).toString(),
        method: "POST",
      }),
      onError
    );
  },
  async patch<TResponseData>(
    url: string,
    body: object,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: JSON.stringify(body),
        method: "PATCH",
      }),
      onError
    );
  },
  async put<TResponseData>(
    url: string,
    body: object,
    options: RequestOptions,
    onError: (error: string) => void
  ) {
    const { params, ...init } = options;
    return resolve<TResponseData>(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: JSON.stringify(body),
        method: "PUT",
      }),
      onError
    );
  },
};

export function retries<T extends unknown[]>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (...args: T) => Promise<NetworkResponse<any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (...args: T) => Promise<NetworkResponse<any>> {
  let retriesRemaining = 3;
  let retryDelaySeconds = 2;
  const retryDecayFactor = 4; // 2, 2*4 = 8, 8*4 = 32
  return async function (...args: T) {
    while (retriesRemaining >= 0) {
      const response = await fn(...(args as T));
      if (response.status >= 500) {
        if (retriesRemaining === 0) {
          console.error("Final error", args, response);
          return response;
        }
        retriesRemaining--;
        console.warn(
          `Waiting ${retryDelaySeconds} seconds (${retriesRemaining} retries remaining)...`,
          args
        );
        await new Promise((r) => setTimeout(r, retryDelaySeconds * 1000));
        retryDelaySeconds *= retryDecayFactor;
        continue;
      }
      return response;
    }
    throw new Error("unreachable");
  };
}

export const network: typeof networkOnce = {
  get: retries(networkOnce.get),
  delete: retries(networkOnce.delete),
  patch: retries(networkOnce.patch),
  post: retries(networkOnce.post),
  postFormData: retries(networkOnce.postFormData),
  put: retries(networkOnce.put),
};
