import { Box, Button, Divider, Group, Stack, Title } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { organizationClient } from "../../../common/utils/entityClient.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { clientError } from "../../utils/clientError.js";
import { OrganizationModal } from "../Modals/OrganizationModal.js";
import { OrganizationsTable } from "../Tables/OrganizationsTable.js";

interface OrganizationManagementProps {
  type: "service_provider" | "customer";
}

export function OrganizationManagement({ type }: OrganizationManagementProps) {
  const { serviceProvider, setCustomers, setCustomer } = useActiveStates();
  const [organizations, setOrganizations] = useState<OrganizationEntity[]>([]);
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const fetchOrganizations = useCallback(async () => {
    const response = await organizationClient(clientError).list();
    if (response.success && "data" in response && "data" in response.data) {
      setOrganizations(
        response.data.data.filter((o) => o.id !== serviceProvider?.id)
      );
    }
  }, [serviceProvider?.id]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  const handleCreate = async (newOrg: Partial<OrganizationEntity>) => {
    const response = await organizationClient(clientError).create(newOrg);
    if (response.success && "data" in response && "data" in response.data) {
      await fetchOrganizations();

      const newCustomer = response.data.data;
      setCustomers((prev: OrganizationEntity[] | undefined) => [
        ...(prev ?? []),
        newCustomer,
      ]);
      setCustomer(newCustomer);

      setCreateModalOpened(false);
    }
  };

  const handleEdit = async (updatedOrg: OrganizationEntity) => {
    if (!updatedOrg.id) return;

    const response = await organizationClient(clientError)
      .item(updatedOrg.id)
      .update(updatedOrg);
    if (response.success && "data" in response && "data" in response.data) {
      await fetchOrganizations();
    }
  };

  const handleDelete = async (organization: OrganizationEntity) => {
    if (!organization.id) return;

    const response = await organizationClient(clientError)
      .item(organization.id)
      .delete();
    if (response.success) {
      await fetchOrganizations();
    }
  };

  const title = type === "service_provider" ? "Service Providers" : "Clients";

  return (
    <Box w="100%">
      <Stack gap={0}>
        <Box py="md" px="lg">
          <Group justify="space-between" align="center">
            <Title order={4}>{title}</Title>
            <Button onClick={() => setCreateModalOpened(true)}>
              Add {type === "service_provider" ? "Service Provider" : "Client"}
            </Button>
          </Group>
        </Box>
        <Divider />

        <OrganizationsTable
          organizations={organizations}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />

        <OrganizationModal
          opened={createModalOpened}
          onClose={() => setCreateModalOpened(false)}
          onSubmit={handleCreate}
          type={type}
          editMode={false}
        />
      </Stack>
    </Box>
  );
}
