import { Box, Text, Title } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";

export function ComponentError({
  title,
  messages,
}: {
  title: string;
  messages: string[];
}) {
  return (
    <Box>
      <Text c={"var(--mantine-color-pink-9)"}>
        <IconExclamationCircle
          size={64}
          style={{
            marginLeft: "-4px",
          }}
        />
      </Text>
      <Title order={3} c={"var(--mantine-color-pink-8)"}>
        {title}
      </Title>
      {messages.map((message, i) => (
        <Text key={i} c={"var(--mantine-color-pink-9)"} mt={10}>
          {message}
        </Text>
      ))}
    </Box>
  );
}
