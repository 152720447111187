import { IntegerField } from "../types/field";

export function referenceIdField<X extends number>(
  x: X
): IntegerField<{ final: true; name: `reference_${X}_id` }> {
  return {
    final: true,
    label: `Reference ${x} ID`,
    name: `reference_${x}_id`,
    type: "integer",
  };
}
