import { OrganizationEntity } from "../../../common/entities/organization.js";
import { UserManagement } from "../../components/Admin/UserManagement.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";

export function PeopleOverview() {
  const { customer, serviceProvider } = useActiveStates();

  // Use customer if available, otherwise use service provider
  const activeOrganization: OrganizationEntity | undefined = customer?.id
    ? customer
    : serviceProvider;

  return (
    <Layout>
      <PageTitle title="Organization Profile" />

      <UserManagement organizationId={activeOrganization.id!} />
    </Layout>
  );
}
