import { UserEntity } from "../../common/entities/user.js";
import { getAuth } from "../hooks/useAuth.js";

export interface StoredAuthProfile {
  profile: UserEntity;
  sessionId: number;
}

export function storeAuthProfile(profile: UserEntity) {
  const { sessionId } = getAuth();

  const existingProfiles: StoredAuthProfile[] = JSON.parse(
    localStorage.getItem("authProfiles") || "[]"
  );

  // Check if the profile already exists to avoid duplicates
  const existingProfileIndex = existingProfiles.findIndex(
    (p) => p.profile.id === profile.id
  );

  if (typeof sessionId !== "number") {
    return;
  }

  if (existingProfileIndex !== -1) {
    existingProfiles[existingProfileIndex] = {
      profile,
      sessionId,
    };
  } else {
    // Add the new profile with its sessionId to the list
    existingProfiles.push({
      profile,
      sessionId: sessionId,
    });
  }
  localStorage.setItem("authProfiles", JSON.stringify(existingProfiles));
}
