import { Box, Button, Divider, Group, Stack, Title } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { UserEntity } from "../../../common/entities/user.js";
import { userClient } from "../../../common/utils/entityClient.js";
import { clientError } from "../../utils/clientError.js";
import { UserModal } from "../Modals/UserModal.js";
import { UsersTable } from "../Tables/UsersTable.js";

interface UserManagementProps {
  organizationId: number;
}

export function UserManagement({ organizationId }: UserManagementProps) {
  const [users, setUsers] = useState<UserEntity[]>([]);
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const fetchUsers = useCallback(async () => {
    const response = await userClient(clientError).list({
      organization_id: organizationId,
    });
    if (response.success && "data" in response && "data" in response.data) {
      setUsers(response.data.data);
    }
  }, [organizationId]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleCreate = async (newUser: Partial<UserEntity>) => {
    const response = await userClient(clientError).create({
      ...newUser,
      organization_id: organizationId,
    });
    if (response.success && "data" in response && "data" in response.data) {
      await fetchUsers();
      setCreateModalOpened(false);
    }
  };

  const handleEdit = async (updatedUser: UserEntity) => {
    if (!updatedUser.id) return;

    const response = await userClient(clientError)
      .item(updatedUser.id)
      .update(updatedUser);
    if (response.success && "data" in response && "data" in response.data) {
      await fetchUsers();
    }
  };

  const handleDelete = async (user: UserEntity) => {
    if (!user.id) return;

    const response = await userClient(clientError).item(user.id).delete();
    if (response.success) {
      await fetchUsers();
    }
  };

  return (
    <Box w="100%">
      <Stack gap={0}>
        <Box py="md" px="lg">
          <Group justify="space-between" align="center">
            <Title order={4}>People</Title>
            <Button onClick={() => setCreateModalOpened(true)}>Add User</Button>
          </Group>
        </Box>
        <Divider />

        <UsersTable users={users} onEdit={handleEdit} onDelete={handleDelete} />

        <UserModal
          opened={createModalOpened}
          onClose={() => setCreateModalOpened(false)}
          onSubmit={handleCreate}
          editMode={false}
        />
      </Stack>
    </Box>
  );
}
