import React, { createContext, useContext, useState } from "react";

type AsideContextType = {
  opened: boolean;
  openPennyChat: () => void;
  closeAside: () => void;
};

const AsideContext = createContext<AsideContextType | undefined>(undefined);

export const AsideProvider: React.FC = ({ children }) => {
  const [opened, setOpened] = useState(false);

  const openPennyChat = () => setOpened(true);
  const closeAside = () => setOpened(false);

  return (
    <AsideContext.Provider value={{ opened, openPennyChat, closeAside }}>
      {children}
    </AsideContext.Provider>
  );
};

export const useAside = () => {
  const context = useContext(AsideContext);
  if (!context) {
    throw new Error("useAside must be used within an AsideProvider");
  }
  return context;
};
