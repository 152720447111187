import { BooleanField } from "../types/field";

export const emailVerifiedField: BooleanField<{
  auto: true;
  name: "email_verified";
  required: true;
}> = {
  auto: true,
  label: "Email Verified",
  name: "email_verified",
  required: true,
  type: "boolean",
};
