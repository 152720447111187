import { Avatar, AvatarProps } from "@mantine/core";
import { getUserInitials } from "../../utils/textUtils.js";
import { UserEntity } from "../../../common/entities/user.js";

export function UserAvatar({
  user,
  ...props
}: { user: UserEntity } & AvatarProps) {
  return (
    <Avatar
      src={user.picture}
      name={getUserInitials(user)}
      alt={`${user.given_name || user.nickname} Profile Picture`}
      {...props}
    />
  );
}
