import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationManagement } from "../../components/Admin/OrganizationManagement.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";

export function ClientsOverview() {
  const { customer, serviceProvider } = useActiveStates();

  // Use customer if available, otherwise use service provider
  const activeOrganization: OrganizationEntity | undefined = customer?.id
    ? customer
    : serviceProvider;

  return (
    <Layout>
      <PageTitle title="Organization Profile" />

      <OrganizationManagement organizationId={activeOrganization.id} />
    </Layout>
  );
}
