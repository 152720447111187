import { Button } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import { UserEntity } from "../../../common/entities/user.js";
import { network } from "../../../common/utils/network.js";
import { useUser } from "../../contexts/ActiveStatesContext.js";

export function SlackButton() {
  const user = useUser();
  const [connected, setConnected] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      if (!user?.email) return;

      try {
        const response = await network.get<{
          users: { email: string; permissions: string[] }[];
        }>(`/api/teams/fetch`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
          params: { email: user.email },
        });
        if ("data" in response) {
          const currentUser = response.data.users.find(
            (u: UserEntity) => u.email === user.email
          );
          setPermissions(currentUser ? currentUser.permissions : []);
        }
      } catch (error) {
        console.error("Error fetching user permissions:", error);
      }
    };

    fetchPermissions();
  }, [user]);

  useEffect(() => {
    const checkSlackConnection = async () => {
      try {
        const response = await network.get<{
          connected: boolean;
          slackData: { team_id: string };
        }>(`/api/slack/status`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
          params: { auth0_user_id: user?.auth0_user_id },
        });
        if ("data" in response) {
          setConnected(response.data.connected);
          if (response.data.connected) {
            const team_id = response.data.slackData.team_id;
            const teamResponse = await network.get<{ team_name: string }>(
              `/api/slack/team-name`,
              {
                headers: {
                  "ngrok-skip-browser-warning": "true",
                },
                params: { team_id },
              }
            );
            {
              if ("data" in teamResponse && teamResponse.data) {
                setTeamName(teamResponse.data.team_name);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error checking Slack connection:", error);
      }
    };

    checkSlackConnection();
  }, [user]);

  const handleConnect = async () => {
    await network.post(
      `/api/slack/store-user`,
      {
        auth0_user_id: user?.auth0_user_id,
      },
      {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      }
    );
    // todo this should happen on the API
    // const slackOAuthUrl = `${env.APP_SLACK_AUTH_URL}?client_id=${env.APP_SLACK_CLIENT_ID}&scope=${env.APP_SLACK_SCOPE}&redirect_uri=/api/slack/callback&team=`;
    // window.location.href = slackOAuthUrl;
  };

  const hasCanAuthenticate =
    permissions && permissions.includes("canAuthenticate");

  if (connected) {
    return (
      <Button variant="subtle" color="white" leftSection={<IconCheck />}>
        Connected to the {teamName} workspace
      </Button>
    );
  }

  return (
    <Button
      onClick={handleConnect}
      variant="white"
      color="dark"
      disabled={!hasCanAuthenticate}
    >
      Connect
    </Button>
  );
}
