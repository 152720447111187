import { createContext, MutableRefObject, useContext } from "react";
import { DataRouterContextObject } from "react-router/dist/lib/context.js";

import { OrganizationEntity } from "../../common/entities/organization.js";
import { OrganizationRelationshipEntity } from "../../common/entities/organizationRelationship.js";
import { OrganizationRelationshipUserEntity } from "../../common/entities/organizationRelationshipUser.js";
import { OrganizationUserEntity } from "../../common/entities/organizationUser.js";
import { UserEntity } from "../../common/entities/user.js";
import { MessageControl } from "../hooks/useMessages.js";

export const globalRouterRef: MutableRefObject<DataRouterContextObject | null> =
  { current: null };

export interface ActiveStatesContextValue {
  customer: OrganizationEntity | undefined;
  customers: OrganizationEntity[] | undefined;
  isCustomerUser: boolean;
  isServiceProviderUser: boolean;
  messageControl: MessageControl;
  organizationRelationship: OrganizationRelationshipEntity | undefined;
  organizationRelationshipUser: OrganizationRelationshipUserEntity | undefined;
  organizationUser: OrganizationUserEntity;
  serviceProvider: OrganizationEntity;
  user: UserEntity;
  setCustomer: (customer: OrganizationEntity | undefined) => void;
  setCustomers: (
    customers:
      | OrganizationEntity[]
      | ((prev: OrganizationEntity[] | undefined) => OrganizationEntity[])
  ) => void;
  setServiceProvider: (provider: OrganizationEntity) => void;
  setUser: (user: UserEntity | undefined) => void;
}

export const ActiveStatesContext = createContext<
  ActiveStatesContextValue | undefined
>(undefined);

export function useActiveStates() {
  const context = useContext(ActiveStatesContext);
  if (!context) {
    throw new Error(
      "useActiveStates must be used within an ActiveStatesProvider"
    );
  }
  return context;
}

export function useUser() {
  const context = useContext(ActiveStatesContext);
  if (!context) {
    throw new Error("useUser must be used within an ActiveStatesProvider");
  }
  return context.user;
}
