import { IntegerField } from "../types/field";

export const idField: IntegerField<{
  auto: true;
  name: "id";
  required: true;
}> = {
  auto: true,
  label: "ID",
  name: "id",
  required: true,
  type: "integer",
};
