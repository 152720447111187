import { IntegerField } from "../types/field";

export const SOCIAL_AUTH_PROVIDER_GOOGLE = 0;

export const SocialAuthProviderLabel = {
  [SOCIAL_AUTH_PROVIDER_GOOGLE]: "Google",
};

export const socialAuthProviderField: IntegerField<{
  final: true;
  name: "social_auth_provider";
}> = {
  final: true,
  label: "Social Auth Provider",
  name: "social_auth_provider",
  type: "integer",
};
