import {
  ActionIcon,
  Menu,
  rem,
  useComputedColorScheme,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconBlendMode,
  IconCircleCheckFilled,
  IconMoon,
  IconSun,
} from "@tabler/icons-react";

type MantineColorScheme = "light" | "dark" | "auto";

export const ColorSwitcherMenu = () => {
  const { colorScheme, setColorScheme } = useMantineColorScheme();
  const dark = useComputedColorScheme() === "dark";

  const menuItems: {
    label: string;
    scheme: MantineColorScheme;
    icon: JSX.Element;
  }[] = [
    { label: "Light", scheme: "light", icon: <IconSun size={16} /> },
    { label: "Dark", scheme: "dark", icon: <IconMoon size={16} /> },
    { label: "Auto", scheme: "auto", icon: <IconBlendMode size={16} /> },
  ];

  return (
    <Menu shadow="md">
      <Menu.Target>
        <ActionIcon variant="default" size="lg">
          {dark ? (
            <IconMoon style={{ width: rem(20), height: rem(20) }} stroke={2} />
          ) : (
            <IconSun style={{ width: rem(20), height: rem(20) }} stroke={2} />
          )}
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {menuItems.map((item) => (
          <Menu.Item
            key={item.scheme}
            onClick={() => setColorScheme(item.scheme)}
            leftSection={item.icon}
            rightSection={
              colorScheme === item.scheme ? (
                <IconCircleCheckFilled color="#00ba7c" size={16} />
              ) : null
            }
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
