import { IntegerField } from "../types/field";

export const customerIdField: IntegerField<{
  final: true;
  name: "customer_id";
}> = {
  final: true,
  label: "Customer ID",
  name: "customer_id",
  type: "integer",
};

export const customerIdFieldRequired: IntegerField<{
  final: true;
  name: "customer_id";
  required: true;
}> = {
  ...customerIdField,
  required: true,
};
