import { Grid, Group, Stack, Text } from "@mantine/core";
import {
  IconArrowsSplit,
  IconBox,
  IconSunElectricity,
} from "@tabler/icons-react";
import {
  OrganizationEntitlementsCustomer,
  OrganizationEntitlementsServiceProvider,
} from "../../../common/configuration/entitlementMap.js";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { ENTITLEMENT_IS_SERVICE_PROVIDER } from "../../../common/fields/entitlements.js";
import { LEDGERAI_SLUG } from "../../../common/fields/slug.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import {
  OrganizationEntitlement,
  UpdateEntitlements,
} from "./OrganizationEntitlement.js";

export function OrganizationEntitlements({
  organization,
  onUpdateEntitlements,
}: {
  organization: OrganizationEntity;
  onUpdateEntitlements: UpdateEntitlements;
}) {
  const isServiceProvider = (
    organization.entitlements as unknown as number[]
  )?.includes(ENTITLEMENT_IS_SERVICE_PROVIDER);

  const { serviceProvider } = useActiveStates();
  const serviceProviderIsLedgerAI = serviceProvider?.slug === LEDGERAI_SLUG;

  const headerRow = (
    <Grid px="xl">
      <Grid.Col span={12}>
        <Stack gap="xs">
          <Text fw={500} size="lg">
            Organization Entitlements
          </Text>
          <Text size="sm" c="dimmed">
            Product features that your organization has access to
          </Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={6}>
        <Text size="sm" fw={500} c="dimmed">
          <Group gap="xs">
            <IconBox size={16} />
            Name
          </Group>
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text size="sm" fw={500} c="dimmed">
          <Group gap="xs">
            <IconSunElectricity size={16} />
            Active
          </Group>
        </Text>
      </Grid.Col>
      {isServiceProvider && serviceProviderIsLedgerAI ? (
        <Grid.Col span={3}>
          <Text size="sm" fw={500} c="dimmed">
            <Group gap="xs">
              <IconArrowsSplit size={16} />
              Client Access
            </Group>
          </Text>
        </Grid.Col>
      ) : null}
    </Grid>
  );

  const entitlements = isServiceProvider
    ? OrganizationEntitlementsServiceProvider
    : OrganizationEntitlementsCustomer;

  return (
    <>
      {headerRow}
      {entitlements.map((entitlementId) => (
        <OrganizationEntitlement
          key={entitlementId}
          organization={organization}
          isServiceProvider={isServiceProvider}
          entitlementId={entitlementId}
          onUpdateEntitlements={onUpdateEntitlements}
        />
      ))}
    </>
  );
}
