import { css } from "../../utils/css.js";

interface ClassNames {
  userBubble: string;
  botBubble: string;
  chatWrapperInner: string;
  infoText: string;
  textarea: string;
  toolResult: string;
}

export default css<ClassNames>`
  .userBubble {
    align-self: flex-end;
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    padding: 10px 20px;
    border-radius: var(--mantine-radius-sm);
    max-width: 70%;
  }

  .botBubble {
    align-self: flex-start;
  }

  .chatWrapperInner {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 100%;
    margin: var(--mantine-spacing-xl) 0;
  }
  .infoText {
    text-align: center;
  }

  .textarea {
    width: 100%;
    margin-bottom: var(--mantine-spacing-xs);
  }

  toolResult: {
    backgroundColor: var(--mantine-color-gray-0),
    padding: var(--mantine-spacing-sm),
    borderRadius: var(--mantine-radius-sm),
    fontSize: var(--mantine-font-size-sm),
    fontFamily: var(--mantine-font-family-monospace),
    whiteSpace: pre-wrap,
    overflowX: auto",
    margin: var(--mantine-spacing-xs) 0,
    border: 1px solid var(--mantine-color-gray-2),
  }
`;
