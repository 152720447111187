import {
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Modal,
  MultiSelect,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";

import { UserEntity, userEntity } from "../../../common/entities/user.js";
import { SocialAuthProviderLabel } from "../../../common/fields/social_auth_provider.js";
import { useForm } from "../../hooks/useForm.js";
import { AccountPrimaryColor } from "../../theme.js";
import { StoredAuthProfile } from "../../utils/storeAuthProfile.js";
import { UserAvatar } from "../Avatars/UserAvatar.js";
import { userClient } from "../../../common/utils/entityClient.js";
import { clientError } from "../../utils/clientError.js";

interface EditProfileModalProps {
  opened: boolean;
  onClose: () => void;
  activeProfile?: StoredAuthProfile | null;
}

const TIMEZONE_OPTIONS = [
  { value: "America/New_York", label: "America/New York (UTC-5)" },
  { value: "America/Chicago", label: "America/Chicago (UTC-6)" },
  { value: "America/Denver", label: "America/Denver (UTC-7)" },
  { value: "America/Los_Angeles", label: "America/Los Angeles (UTC-8)" },
  { value: "America/Phoenix", label: "America/Phoenix (UTC-7)" },
  { value: "Europe/London", label: "Europe/London (UTC+0)" },
  { value: "Europe/Paris", label: "Europe/Paris (UTC+1)" },
  { value: "Europe/Berlin", label: "Europe/Berlin (UTC+1)" },
  { value: "Asia/Tokyo", label: "Asia/Tokyo (UTC+9)" },
  { value: "Asia/Shanghai", label: "Asia/Shanghai (UTC+8)" },
  { value: "Asia/Dubai", label: "Asia/Dubai (UTC+4)" },
  { value: "Australia/Sydney", label: "Australia/Sydney (UTC+11)" },
  { value: "Pacific/Auckland", label: "Pacific/Auckland (UTC+13)" },
] as const;

export function EditProfileModal({
  opened,
  onClose,
  activeProfile,
}: EditProfileModalProps) {
  const [updatedProfile, setUpdatedProfile] =
    useState<StoredAuthProfile | null>(null);

  useEffect(() => {
    if (activeProfile) {
      setUpdatedProfile({
        ...activeProfile,
        profile: {
          ...activeProfile.profile,
          contact_preference: (activeProfile.profile.contact_preference ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            []) as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          contact_times: (activeProfile.profile.contact_times || []) as any,
        },
      });
    }
  }, [activeProfile]);

  // Handle input changes
  const handleInputChange = (
    field: keyof StoredAuthProfile["profile"],
    value: string | string[]
  ) => {
    setUpdatedProfile((prev) => ({
      ...prev!,
      profile: {
        ...prev!.profile,
        [field]: value,
      },
    }));
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (data: Partial<UserEntity>) => {
      if (!activeProfile?.profile.id) {
        return;
      }
      if (
        !updatedProfile!.profile.given_name ||
        !updatedProfile!.profile.email
      ) {
        alert("Please fill in required fields.");
        return;
      }
      await userClient(clientError)
        .item(activeProfile?.profile.id)
        .update(data);
      onClose();
    },
    [onClose]
  );

  const hasSocialAuthProvider =
    typeof updatedProfile?.profile?.social_auth_provider === "number";

  const form = useForm(userEntity, {
    initialFormData: activeProfile?.profile ?? ({} as UserEntity),
    onClose,
    onSubmit,
    opened,
    type: "update",
    errorComponent(message: string) {
      return <Text c="red">{message}</Text>;
    },
  });

  // Don't render the form until we have a profile
  if (!updatedProfile) {
    return <Loader />;
  }

  return (
    <Modal opened={opened} onClose={onClose} centered size="xl">
      <Card pt={0} bg="transparent">
        <UserAvatar
          color={AccountPrimaryColor}
          variant="filled"
          user={updatedProfile?.profile ?? activeProfile?.profile}
          size={80}
          radius={100}
          mx="auto"
        />
        <Text ta="center" fz="lg" fw={500} mt="md">
          Edit Profile
        </Text>
      </Card>
      <form onSubmit={form.submit}>
        <Grid gutter="md">
          {/* Auth Provider Managed Fields */}
          <Grid.Col span={6}>
            <Text fw={500} size="sm" mb="xs">
              {typeof updatedProfile?.profile?.social_auth_provider === "number"
                ? `Managed by ${
                    SocialAuthProviderLabel[
                      updatedProfile.profile.social_auth_provider as 0
                    ]
                  }`
                : "Auth Provider Details"}
            </Text>
            <TextInput
              label="First Name"
              placeholder="First name"
              required
              value={form.data.given_name}
              onChange={(e) =>
                form.handleInputChange("given_name", e.currentTarget.value)
              }
              mb="sm"
              disabled={hasSocialAuthProvider}
            />
            <TextInput
              label="Last Name"
              placeholder="Last name"
              value={form.data.family_name}
              onChange={(e) =>
                form.handleInputChange("family_name", e.currentTarget.value)
              }
              mb="sm"
              disabled={hasSocialAuthProvider}
            />
            <TextInput
              label="Email"
              placeholder="Email address"
              required
              value={form.data.email}
              onChange={(e) =>
                form.handleInputChange("email", e.currentTarget.value)
              }
              mb="sm"
              disabled={hasSocialAuthProvider}
            />
            <TextInput
              label="Profile Picture URL"
              placeholder="https://example.org/image.jpg"
              value={form.data.picture}
              onChange={(e) =>
                form.handleInputChange("picture", e.currentTarget.value)
              }
              mb="sm"
              disabled={hasSocialAuthProvider}
            />
          </Grid.Col>

          {/* User Customizable Fields */}
          <Grid.Col span={6}>
            <Text fw={500} size="sm" mb="xs">
              Preferences
            </Text>

            <MultiSelect
              label="Contact Preferences"
              data={[
                { value: "0", label: "Email" },
                { value: "1", label: "Phone" },
                { value: "2", label: "Text" },
              ]}
              placeholder="Choose preferences"
              value={
                (
                  updatedProfile.profile
                    .contact_preference as unknown as number[]
                )?.map(String) || []
              }
              onChange={(value) =>
                form.handleInputChange("contact_preference", value as string[])
              }
              mb="sm"
            />
            <Select
              label="Timezone"
              placeholder="Select timezone"
              data={TIMEZONE_OPTIONS}
              value={form.data.timezone}
              onChange={(value) => handleInputChange("timezone", value || "")}
              searchable
              mb="sm"
            />
            <TextInput
              label="Contact Times"
              placeholder="Preferred contact times"
              value={form.data.contact_times?.join(", ")}
              onChange={(e) =>
                form.handleInputChange(
                  "contact_times",
                  e.currentTarget.value.split(", ")
                )
              }
              mb="sm"
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <Text fw={500} size="sm" mb="xs">
              About
            </Text>
            <Textarea
              placeholder={
                "Describe your skills, fun facts, or anything else about yourself..."
              }
              value={form.data.about}
              onChange={(e) =>
                form.handleInputChange("about", e.currentTarget.value)
              }
            ></Textarea>
          </Grid.Col>
        </Grid>
        <Group mt="md">
          <Button type="submit" fullWidth>
            Save Changes
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
