import {
  Center,
  Loader,
  MantineProvider,
  Stack,
  Transition,
  useComputedColorScheme,
} from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";

import "./App.css.js";

import { OrganizationEntity } from "../common/entities/organization.js";
import { OrganizationRelationshipEntity } from "../common/entities/organizationRelationship.js";
import { OrganizationRelationshipUserEntity } from "../common/entities/organizationRelationshipUser.js";
import { OrganizationUserEntity } from "../common/entities/organizationUser.js";
import { UserEntity } from "../common/entities/user.js";
import { pictureField } from "../common/fields/picture.js";
import { pictureBackgroundColorField } from "../common/fields/picture_background_color.js";
import { initClient } from "../common/utils/initClient.js";
import { OrganizationAvatar } from "./components/Avatars/OrganizationAvatar.js";
import { ActiveStatesContext } from "./contexts/ActiveStatesContext.js";
import { AsideProvider } from "./contexts/AsideContext";
import { useAuth } from "./hooks/useAuth.js";
import { useMessages } from "./hooks/useMessages.js";
import { useSessionState } from "./hooks/useSessionState.js";
import { ActivityOverview } from "./pages/activity/index.js";
import { ClientsOverview } from "./pages/clients/overview.js";
import { ConnectOverview } from "./pages/connect/overview.js";
import { DashboardOverview } from "./pages/dashboard/index.js";
import { NotFound } from "./pages/NotFound.js";
import { PennyOverview } from "./pages/penny/overview.js";
import { PeopleOverview } from "./pages/people/overview.js";
import { RecipesOverview } from "./pages/recipes/overview.js";
import { SettingsOverview } from "./pages/settings/overview.js";
import { SystemError } from "./pages/SystemError.js";
import { Welcome } from "./pages/welcome/index.js";
import { PrimaryColor, theme } from "./theme.js";
import { clientError } from "./utils/clientError.js";
import { storeAuthProfile } from "./utils/storeAuthProfile.js";

const outerRouter = createBrowserRouter([{ path: "*", Component: AppRoot }], {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
  },
});

const appRoutes = (
  <Routes>
    <Route path="/" Component={Welcome} />
    <Route path="/dashboard" Component={DashboardOverview} />
    <Route path="/activity" Component={ActivityOverview} />
    <Route path="/penny/overview" Component={PennyOverview} />
    <Route path="/recipes/overview" Component={RecipesOverview} />
    <Route path="/connect/overview" Component={ConnectOverview} />
    <Route path="/settings/overview" Component={SettingsOverview} />
    <Route path="/people/overview" Component={PeopleOverview} />
    <Route path="/clients/overview" Component={ClientsOverview} />
    <Route path="*" Component={NotFound} />
  </Routes>
);

export function App() {
  const { readURLHash, logOut } = useAuth();

  useEffect(() => {
    readURLHash((url) => window.location.replace(url));
  }, [readURLHash]);

  const [customerIdHint, setCustomerIdHint] = useSessionState<
    number | undefined
  >("customerIdHint", undefined);

  const [initError, setInitError] = useState<string | undefined>();
  const [user, setUser] = useState<UserEntity | undefined>();
  const [isServiceProviderUser, setIsServiceProviderUser] = useState(false);
  const [serviceProvider, setServiceProvider] = useState<OrganizationEntity>();
  const [customer, _setCustomer] = useState<OrganizationEntity | undefined>();
  const [customers, setCustomers] = useState<
    OrganizationEntity[] | undefined
  >();
  const [organizationRelationship, setOrganizationRelationship] = useState<
    OrganizationRelationshipEntity | undefined
  >();
  const [organizationRelationshipUser, setOrganizationRelationshipUser] =
    useState<OrganizationRelationshipUserEntity | undefined>();
  const [organizationUser, setOrganizationUser] =
    useState<OrganizationUserEntity>();

  const setCustomer = useCallback(
    (customer: OrganizationEntity | undefined) => {
      _setCustomer(customer);
      if (Array.isArray(customers) && typeof customer !== "undefined") {
        setCustomers(
          customers.map((c) => (c.id === customer?.id ? customer : c))
        );
      }
      if (customer) {
        setCustomerIdHint(customer.id);
      } else {
        setCustomerIdHint(undefined);
      }
    },
    [customers, setCustomerIdHint]
  );

  const messageControl = useMessages(user, serviceProvider?.id, customer?.id);

  const { loadMessages } = messageControl;

  const loadOnceRef = useRef(false);

  useEffect(
    function () {
      async function load() {
        const initResponse = await initClient(clientError).get({
          customerIdHint,
        });
        if (initResponse.success) {
          const {
            customer,
            customers,
            isServiceProviderUser,
            organizationRelationship,
            organizationRelationshipUser,
            organizationUser,
            serviceProvider,
            user,
          } = initResponse.data;

          setCustomer(customer);
          setCustomers(customers);
          setIsServiceProviderUser(isServiceProviderUser);
          setOrganizationRelationship(organizationRelationship);
          setOrganizationRelationshipUser(organizationRelationshipUser);
          setOrganizationUser(organizationUser);
          setServiceProvider(serviceProvider);
          setUser(user);
          storeAuthProfile(user);
        } else {
          console.error(initResponse.error);
          if (initResponse.status === 401) {
            setUser(undefined);
            logOut((url) => window.location.replace(url));
          } else {
            setInitError(initResponse.error);
          }
        }
      }
      if (!loadOnceRef.current) {
        loadOnceRef.current = true;
        load().catch((error) => {
          setInitError(error.message);
        });
      }
    },
    [customerIdHint, logOut, setCustomer]
  );

  useEffect(() => {
    if (user) {
      loadMessages(user.id!, serviceProvider?.id, customer?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, customer, serviceProvider]);

  const computedColorScheme = useComputedColorScheme("light");

  if (initError) {
    return <SystemError error={initError} />;
  }

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 1000);
  }, []);

  const [asideOpened, setAsideOpened] = useSessionState("asideOpened", false);

  if (!user || !serviceProvider || !organizationUser) {
    return (
      <Center h="100vh" w="100vw">
        <Stack ta="center" gap="xl">
          <OrganizationAvatar
            style={{ border: "2px solid var(--mantine-color-body)" }}
            variant="filled"
            color={PrimaryColor}
            size={120}
            radius="md"
            organization={{
              [pictureField.name]: "https://app.ledger.ai/images/logo.svg",
              [pictureBackgroundColorField.name]:
                computedColorScheme === "dark" ? "#808080" : "#f0f0f0",
            }}
          />
          {isMounted ? null : <Loader mx="auto" opacity={0} />}
          <Transition mounted={isMounted} transition="fade" duration={1000}>
            {(styles) => <Loader mx="auto" style={styles} />}
          </Transition>
        </Stack>
      </Center>
    );
  }

  return (
    <ActiveStatesContext.Provider
      value={{
        customer,
        customers,
        isCustomerUser: !isServiceProviderUser,
        isServiceProviderUser,
        messageControl,
        organizationRelationship,
        organizationRelationshipUser,
        organizationUser,
        serviceProvider,
        user,
        setCustomer,
        setCustomers,
        setServiceProvider,
        setUser,
      }}
    >
      <MantineProvider
        defaultColorScheme="auto"
        theme={theme(
          serviceProvider?.theme_primary_color || "#231F20",
          customer?.theme_primary_color || "#231F20",
          isServiceProviderUser
        )}
      >
        <AsideProvider>
          <RouterProvider
            router={outerRouter}
            future={{ v7_startTransition: true }}
          />
        </AsideProvider>
      </MantineProvider>
    </ActiveStatesContext.Provider>
  );
}

export function AppRoot() {
  return <>{appRoutes}</>;
}
