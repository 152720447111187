import { StringField } from "../types/field";

export const slugField: StringField<{ name: "slug"; required: true }> = {
  label: "Handle",
  name: "slug",
  required: true,
  type: "string",
};

export const LEDGERAI_SLUG = "ledgerai";
