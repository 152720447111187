import { IntegerField } from "../types/field";

export const extendEntitlementsField: IntegerField<{
  array: true;
  name: "extend_entitlements";
  required: true;
}> = {
  array: true,
  label: "Extend Entitlements",
  name: "extend_entitlements",
  required: true,
  type: "integer",
};
