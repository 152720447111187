import { StringField } from "../types/field";

export const givenNameField: StringField<{
  name: "given_name";
  required: true;
}> = {
  label: "First Name",
  name: "given_name",
  required: true,
  type: "string",
};
