import {
  Box,
  Button,
  Menu,
  MenuLabel,
  Stack,
  Text,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganizationUpdateType } from "../../../common/entities/organization.js";
import { organizationClient } from "../../../common/utils/entityClient.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { ServiceProviderPrimaryColor } from "../../theme.js";
import { clientError } from "../../utils/clientError.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";
import { OrganizationModal } from "../Modals/OrganizationModal.js";

export function ServiceProviderMenu() {
  const { serviceProvider, isCustomerUser, setServiceProvider, setCustomer } =
    useActiveStates();

  const navigate = useNavigate();
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const handleServiceProviderSubmit = async (patch: OrganizationUpdateType) => {
    const updated = await organizationClient(clientError)
      .item(serviceProvider.id!)
      .update(patch);

    if (updated.success && updated.data.success) {
      setServiceProvider(updated.data.data);
    }
  };

  if (isCustomerUser) {
    return (
      <Tooltip
        label={serviceProvider?.name || "Service provider"}
        position="right"
        withArrow
        transitionProps={{ duration: 0 }}
      >
        <OrganizationAvatar
          organization={serviceProvider}
          variant="filled"
          size={45}
          radius="sm"
          color={ServiceProviderPrimaryColor}
        />
      </Tooltip>
    );
  }

  return (
    <>
      <Menu shadow="md" position="top" withArrow>
        <Menu.Target>
          <Tooltip
            label={serviceProvider?.name || "Service provider"}
            position="right"
            withArrow
            transitionProps={{ duration: 0 }}
          >
            <UnstyledButton>
              <OrganizationAvatar
                organization={serviceProvider}
                variant="filled"
                size={45}
                radius="sm"
                bg={ServiceProviderPrimaryColor}
              />
            </UnstyledButton>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          {
            <>
              <Box p="lg">
                <Stack gap={0}>
                  <OrganizationAvatar
                    organization={serviceProvider}
                    variant="filled"
                    color={ServiceProviderPrimaryColor}
                    bg={ServiceProviderPrimaryColor}
                    size={60}
                    radius="sm"
                    mx="auto"
                  />
                  <Text ta="center" fz="lg" fw={500} mt="md">
                    {serviceProvider.name}
                  </Text>
                  <Text ta="center" c="dimmed" fz="sm">
                    @{serviceProvider.slug}
                  </Text>
                  <Button
                    size="xs"
                    mt="sm"
                    variant="light"
                    fullWidth
                    onClick={() => setModalOpened(true)}
                    color={ServiceProviderPrimaryColor}
                  >
                    Edit Profile
                  </Button>
                </Stack>
              </Box>
              <Menu.Divider />
              <MenuLabel>Service</MenuLabel>
              <Menu.Item
                onClick={() => {
                  setCustomer({});
                  setServiceProvider(serviceProvider);
                  navigate("/dashboard");
                }}
              >
                Dashboard
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setCustomer({});
                  setServiceProvider(serviceProvider);
                  navigate("/activity");
                }}
              >
                Activity
              </Menu.Item>
              <MenuLabel>Configure</MenuLabel>
              <Menu.Item
                onClick={() => {
                  setCustomer({});
                  setServiceProvider(serviceProvider);
                  navigate("/profile/overview");
                }}
              >
                Manage Team
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setCustomer({});
                  setServiceProvider(serviceProvider);
                  navigate("/profile/overview");
                }}
              >
                Manage Service Providers
              </Menu.Item>
            </>
          }
        </Menu.Dropdown>
      </Menu>

      <OrganizationModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        onSubmit={handleServiceProviderSubmit}
        type="service_provider"
        editMode={true}
        initialData={serviceProvider}
      />
    </>
  );
}
