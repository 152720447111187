import { Container, Stack, Text, Title } from "@mantine/core";

import classes from "./welcome/welcome.css.js";

export function SystemError({ error }: { error: string }) {
  return (
    <div className={classes.wrapper}>
      <Container size="xl">
        <Stack>
          <Title className={classes.title} order={1}>
            {"We're sorry :("}
          </Title>
          <Text className={classes.description}>
            An error occurred while loading the page. Please try again later.
          </Text>
          <Text className={classes.error}>{error}</Text>
        </Stack>
      </Container>
    </div>
  );
}
