import { Avatar, AvatarProps } from "@mantine/core";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { PrimaryColor } from "../../theme.js";
import { getInitials } from "../../utils/textUtils.js";

export function OrganizationAvatar({
  organization,
  ...props
}: { organization: OrganizationEntity } & AvatarProps) {
  const { bg, color, ...rest } = props;
  return (
    <Avatar
      alt={`${organization.name} Profile Picture`}
      bg={organization.picture_background_color || bg || PrimaryColor}
      color={organization.theme_primary_color || color || PrimaryColor}
      fw={900}
      name={organization.initials || getInitials(organization.name ?? "-")}
      src={organization.picture}
      variant="filled"
      {...rest}
    />
  );
}
