import {
  Box,
  Card,
  Grid,
  NavLink,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconBell,
  IconBuilding,
  IconChevronRight,
  IconCreditCard,
} from "@tabler/icons-react";
import { useState } from "react";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { CompanyOverview } from "../../components/Admin/CompanyOverview.js";
import { OrganizationManagement } from "../../components/Admin/OrganizationManagement.js";
import { UserManagement } from "../../components/Admin/UserManagement.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";

const SETTINGS_NAV = [
  {
    label: "Your Company",
    description: "Details about your company information",
    icon: IconBuilding,
    active: true,
  },
  {
    label: "Billing",
    description: "Review invoices and setup your payment methods",
    icon: IconCreditCard,
  },
  {
    label: "Notifications Setting",
    description: "Customize your notifications",
    icon: IconBell,
  },
] as const;

export function SettingsOverview() {
  const { customer, serviceProvider } = useActiveStates();
  const [activeSection, setActiveSection] =
    useState<(typeof SETTINGS_NAV)[number]["label"]>("Your Company");

  // Use customer if available, otherwise use service provider
  const activeOrganization: OrganizationEntity | undefined = customer?.id
    ? customer
    : serviceProvider;
  const isCustomer = !!customer?.id;

  if (!activeOrganization) {
    return (
      <Layout>
        <Text>No organization selected</Text>
      </Layout>
    );
  }

  const renderContent = () => {
    switch (activeSection) {
      case "Your Company":
        return <CompanyOverview activeOrganization={activeOrganization} />;
      case "Billing":
        return (
          <Card withBorder radius="md" p="md">
            <Text>Billing content coming soon</Text>
          </Card>
        );
      case "User Management":
        return <UserManagement organizationId={activeOrganization.id!} />;
      case "Client Portfolio":
        return (
          <OrganizationManagement
            type={isCustomer ? "customer" : "service_provider"}
          />
        );

      // Add other cases as needed
      default:
        return (
          <Card withBorder radius="md" p="md">
            <Text>Content for {activeSection} coming soon</Text>
          </Card>
        );
    }
  };

  return (
    <Layout>
      <PageTitle title="Organization Profile" />

      <Grid gutter={0} h="100vh - var(--app-shell-header-height)">
        <Grid.Col span={{ base: 12, sm: 4, md: 3, lg: 3 }}>
          <Stack>
            <Box p="md">
              {SETTINGS_NAV.map((item) => (
                <NavLink
                  variant="subtle"
                  key={item.label}
                  label={item.label}
                  description={item.description}
                  leftSection={<item.icon size={24} stroke={2} />}
                  rightSection={<IconChevronRight size={14} stroke={2} />}
                  active={activeSection === item.label}
                  onClick={() => setActiveSection(item.label)}
                />
              ))}
            </Box>
          </Stack>
        </Grid.Col>

        <Grid.Col
          span={{ base: 12, sm: 8, md: 9, lg: 9 }}
          style={{
            borderLeft: "1px solid var(--app-shell-border-color)",
          }}
        >
          <ScrollArea style={{ height: "100%" }}>{renderContent()}</ScrollArea>
        </Grid.Col>
      </Grid>
    </Layout>
  );
}
