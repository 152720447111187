import {
  Button,
  Group,
  Modal,
  PasswordInput,
  Stack,
  TextInput,
} from "@mantine/core";
import { FormEvent, useState } from "react";
import { UserEntity } from "../../../common/entities/user.js";

interface UserModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<UserEntity>) => void;
  editMode?: boolean;
  initialData?: UserEntity;
}

export function UserModal({
  opened,
  onClose,
  onSubmit,
  editMode = false,
  initialData,
}: UserModalProps) {
  const [email, setEmail] = useState(initialData?.email || "");
  const [firstName, setFirstName] = useState(initialData?.given_name || "");
  const [lastName, setLastName] = useState(initialData?.family_name || "");
  // const [phone, setPhone] = useState(initialData?.phone || "");
  // const [active, setActive] = useState(initialData?.active ?? true);
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!firstName || firstName.length < 2) {
      newErrors.firstName = "First name must be at least 2 characters";
    }

    if (!lastName || lastName.length < 2) {
      newErrors.lastName = "Last name must be at least 2 characters";
    }

    if (!editMode && (!password || password.length < 8)) {
      newErrors.password = "Password must be at least 8 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const userData: Partial<UserEntity> = {
      email,
      given_name: firstName,
      family_name: lastName,
      // active,
    };

    if (!editMode && password) {
      // userData.password = password;
    }

    onSubmit(userData);
    handleReset();
  };

  const handleReset = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    // setPhone("");
    // setActive(true);
    setPassword("");
    setErrors({});
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        handleReset();
      }}
      title={editMode ? "Edit User" : "Add New User"}
      size="md"
    >
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextInput
            label="Email"
            placeholder="user@example.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errors.email}
          />
          <Group grow>
            <TextInput
              label="First Name"
              placeholder="John"
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              error={errors.firstName}
            />
            <TextInput
              label="Last Name"
              placeholder="Doe"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              error={errors.lastName}
            />
          </Group>
          {/* <TextInput
            label="Phone"
            placeholder="+1 234 567 8900"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          /> */}
          {!editMode && (
            <PasswordInput
              label="Password"
              placeholder="Enter password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errors.password}
            />
          )}
          {/* <Switch
            label="Active"
            checked={active}
            onChange={(e) => setActive(e.currentTarget.checked)}
          /> */}
          <Group justify="flex-end">
            <Button variant="light" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">{editMode ? "Save" : "Create"}</Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
