import { customerIdField } from "../fields/customer_id";
import { providerIdField } from "../fields/provider_id";
import { timestampField } from "../fields/timestamp";
import { userIdField } from "../fields/user_id";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

export const fields = [
  userIdField,
  providerIdField,
  customerIdField,
  timestampField,
] satisfies FieldList;

export const primaryKeyFields = [
  userIdField,
  providerIdField,
  customerIdField,
] satisfies PrimaryKeyFieldTuple;

export const messagesLastReadEntity = entity({
  api: {
    collectionPath: "messages-last-read",
    itemPath: "messages-last-read/mark-read",
    list: {
      missingFilterImpliesNull: true,
    },
  },
  name: "MessagesLastRead",
  fields,
  primaryKeyFields,
  tableName: "messages_last_read",
});

export type MessagesLastReadEntity = EntityType<
  typeof messagesLastReadEntity.fields,
  typeof messagesLastReadEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleMessagesLastRead: MessagesLastReadEntity = {
  provider_id: 765,
  timestamp: new Date().toISOString(),
  user_id: 543,
};
