import {
  Box,
  Button,
  Group,
  Menu,
  MenuItem,
  MenuLabel,
  ScrollArea,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { IconCircleCheckFilled, IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { LEDGERAI_SLUG } from "../../../common/fields/slug.js";
import { organizationClient } from "../../../common/utils/entityClient.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { PrimaryColor } from "../../theme.js";
import { clientError } from "../../utils/clientError.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";
import { OrganizationModal } from "../Modals/OrganizationModal.js";

export const CustomerMenu = () => {
  const navigate = useNavigate();
  const {
    customer: activeCustomer,
    customers,
    serviceProvider,
    isCustomerUser,
    setCustomer,
    setCustomers,
  } = useActiveStates();

  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  // Use active customer if available, otherwise use service provider
  const activeOrganization = activeCustomer?.id
    ? activeCustomer
    : serviceProvider;
  const isLedgerAI = serviceProvider?.slug === LEDGERAI_SLUG;

  const handleSubmit = async (newOrg: Partial<OrganizationEntity>) => {
    const response = await organizationClient(clientError).create(newOrg);
    if (response.success && response.data.success) {
      const createdOrg = response.data.data;
      setCustomers((prev) => [...(prev ?? []), createdOrg]);
      setCustomer(createdOrg);
    } else if (response.success) {
      throw new Error(
        response.data.success === false
          ? response.data.errors.join(";")
          : `HTTP ${response.status.toString(10)}`
      );
    } else {
      try {
        const errResponse = JSON.parse(response.text);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errError: any = new Error(
          errResponse.errors?.join("; ") ?? response.text
        );
        errError.errors = errResponse.errors;
        throw errError;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if ("errors" in e) {
          throw e;
        }
        throw new Error(
          `${e?.message ?? e}; ${
            response.error ?? `HTTP ${response.status.toString(10)}}`
          }`
        );
      }
    }
  };

  // Simplified text helper - only handles the variable parts
  const menuText = isLedgerAI
    ? {
        label: "LedgerAI Service Providers",
        button: "a service provider",
        empty: "No service providers yet",
      }
    : {
        label: `${serviceProvider?.name} Customers`,
        button: "a customer",
        empty: "No customers yet",
      };

  if (isCustomerUser) {
    return (
      <Group gap={5}>
        <OrganizationAvatar
          organization={activeOrganization}
          variant="filled"
          mr="lg"
          size={45}
          radius="sm"
        />
        <Title order={4}>
          {activeOrganization?.name ?? "No organization selected"}
        </Title>
      </Group>
    );
  }

  if (!Array.isArray(customers)) {
    throw new Error("Customers must be an array");
  }

  return (
    <>
      <Menu withArrow shadow="md" opened={menuOpened} onChange={setMenuOpened}>
        <Menu.Target>
          <UnstyledButton onClick={() => setMenuOpened((o) => !o)}>
            <Group gap={5}>
              <OrganizationAvatar
                organization={activeOrganization}
                mr="lg"
                size={45}
                radius="sm"
                color={PrimaryColor}
                bg={PrimaryColor}
                src={activeOrganization?.picture}
                style={{
                  boxShadow:
                    customers.length > 1
                      ? "0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)"
                      : "none",
                }}
              />

              {activeCustomer?.name && (
                <Title order={4} mr={10}>
                  {activeCustomer?.name}
                </Title>
              )}
            </Group>
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          <Box p="lg">
            <Stack gap={0}>
              <OrganizationAvatar
                organization={activeOrganization}
                variant="filled"
                color={PrimaryColor}
                bg={PrimaryColor}
                size={60}
                radius="sm"
                mx="auto"
                style={{
                  boxShadow:
                    customers.length > 1
                      ? "0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)"
                      : "none",
                }}
              />
              <Text ta="center" fz="lg" fw={500} mt="md">
                {activeOrganization.name}
              </Text>
              <Text ta="center" c="dimmed" fz="sm">
                @{activeOrganization.slug}
              </Text>
              <Group justify="center" mt="sm">
                <Button
                  size="xs"
                  variant="light"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the menu from toggling
                    navigate("/settings/overview");
                  }}
                  color={PrimaryColor}
                >
                  Preferences
                </Button>
                {activeCustomer && (
                  <Button
                    size="xs"
                    variant="light"
                    onClick={() => {
                      setCustomer(undefined);
                      setMenuOpened(false);
                    }}
                  >
                    Clear selection
                  </Button>
                )}
              </Group>
            </Stack>
          </Box>
          <Menu.Divider />
          <MenuLabel>{menuText.label}</MenuLabel>

          <ScrollArea.Autosize mah={280} type="scroll">
            {customers.length > 0 ? (
              customers.map((org) => (
                <MenuItem
                  key={org.id}
                  onClick={() => setCustomer(org)}
                  leftSection={
                    <OrganizationAvatar
                      organization={org}
                      variant="filled"
                      size={45}
                      color={org.theme_primary_color ?? "#228BE6"}
                      radius="sm"
                    />
                  }
                  rightSection={
                    org.id === activeCustomer?.id ? (
                      <IconCircleCheckFilled color="#00ba7c" size={19} />
                    ) : null
                  }
                >
                  <Stack mr="xl" gap={0}>
                    <Text fw={700}> {org.name}</Text>
                    <Text color="dimmed" size="sm" fw={500}>
                      @{org.slug}
                    </Text>
                  </Stack>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>{menuText.empty}</MenuItem>
            )}
          </ScrollArea.Autosize>

          <Menu.Divider />
          <Box m="lg">
            <Button
              size="sm"
              variant="default"
              fullWidth
              onClick={() => setCreateModalOpened(true)}
              leftSection={<IconPlus size={16} stroke={2} />}
            >
              Add {menuText.button}
            </Button>
          </Box>
        </Menu.Dropdown>
      </Menu>

      <OrganizationModal
        opened={createModalOpened}
        onClose={() => setCreateModalOpened(false)}
        onSubmit={handleSubmit}
        type={isLedgerAI ? "service_provider" : "customer"}
        editMode={false}
      />
    </>
  );
};
