import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import {
  customerIdField,
  customerIdFieldRequired,
} from "../fields/customer_id";
import {
  providerIdField,
  providerIdFieldRequired,
} from "../fields/provider_id";
import { entity, EntityType } from "../types/entity";

export const organizationRelationshipEntity = entity({
  api: {
    collectionPath: "organization-relationships",
    itemPath: "organization-relationships/[provider_id]/[customer_id]",
  },
  name: "OrganizationRelationship",
  fields: [
    archivedAtField,
    createdAtField,
    customerIdFieldRequired,
    providerIdFieldRequired,
  ],
  primaryKeyFields: [providerIdField, customerIdField],
  tableName: "organization_relationships",
});

export type OrganizationRelationshipEntity = EntityType<
  typeof organizationRelationshipEntity.fields,
  typeof organizationRelationshipEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleOrganizationRelationship: OrganizationRelationshipEntity = {
  archived_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  customer_id: 765,
  provider_id: 543,
};
