import { css } from "../../utils/css.js";

interface NotificationClasses {
  sectionAsideHeaderBorder: string;
  sectionAsideFooterBorder: string;
}

export default css<NotificationClasses>`
  .sectionAsideHeaderBorder {
    max-height: rem(70);
    min-height: rem(70);
    border-bottom: 1px solid var(--app-shell-border-color);
  }

  .sectionAsideFooterBorder {
    max-height: rem(100);
    min-height: rem(100);
    border-top: 1px solid var(--app-shell-border-color);
  }
`;
