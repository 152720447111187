import { useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { useAside } from "../../contexts/AsideContext.js";

import { MessageEntity } from "../../../common/entities/message.js";
import { OrganizationEntity } from "../../../common/entities/organization.js";
import { UserEntity } from "../../../common/entities/user.js";

import { Box, Group, Text } from "@mantine/core";
import { IconMessageCircle } from "@tabler/icons-react";
import { messageWithDefaultSwapsReact } from "./messageWithDefaultSwapsReact.js";

export function MessageTicket({
  message,
}: // focused,
// focusable = true,
// read = false,
{
  message: MessageEntity;
  focused?: boolean;
  focusable?: boolean;
  read?: boolean;
}) {
  const navigate = useNavigate();
  const { openPennyChat } = useAside();

  const handleReplyInThread = () => {
    openPennyChat();
  };

  const { data } = message as MessageEntity & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { user: UserEntity; organization: OrganizationEntity; item: any };
  };
  return (
    <Box>
      <Text size="sm" mb={5}>
        {messageWithDefaultSwapsReact(
          message.message!,
          (data as { user: UserEntity }).user,
          (data as { organization: OrganizationEntity }).organization,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (data as { item: any }).item
        )}
      </Text>

      <Group justify="space-between">
        {message.timestamp && (
          <>
            <Text c="dimmed" size="xs">
              {new Date(message.timestamp).toLocaleString("en-US", {
                weekday: "long",
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })}
            </Text>
            <Text
              c="dimmed"
              size="xs"
              title={new Date(message.timestamp).toLocaleString()}
            >
              <ReactTimeAgo date={new Date(message.timestamp)} locale="en-US" />
            </Text>
          </>
        )}
      </Group>

      <Group style={{ opacity: 0.5 }} gap="4px" pt="xs">
        <IconMessageCircle />
        <Text size="sm" onClick={handleReplyInThread}>
          Reply in thread
        </Text>
      </Group>
    </Box>
  );
}
