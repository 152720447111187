import { Button } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import { network } from "../../../common/utils/network.js";
import { useUser } from "../../contexts/ActiveStatesContext.js";
import { UserEntity } from "../../../common/entities/user.js";

export function QuickbooksButton() {
  const user = useUser();
  const [connected, setConnected] = useState(false);
  const [companyName, setCompanyName] = useState<string | undefined>(undefined);
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      if (!user?.email) return;

      try {
        const response = await network.get<{
          users: { email: string; permissions: string[] }[];
        }>(`/api/teams/fetch`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
          params: { email: user.email },
        });
        if ("data" in response) {
          const currentUser = response.data.users.find(
            (u: UserEntity) => u.email === user.email
          );
          setPermissions(currentUser ? currentUser.permissions : []);
        }
      } catch (error) {
        console.error("Error fetching user permissions:", error);
      }
    };

    fetchPermissions();
  }, [user]);

  useEffect(() => {
    const checkQuickbooksConnection = async () => {
      try {
        const response = await network.get<{
          connected: boolean;
          companyName: string;
        }>(`/api/quickbooks/status`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
          params: { auth0_user_id: user?.auth0_user_id },
        });
        if ("data" in response) {
          setConnected(response.data.connected);
          setCompanyName(response.data.companyName);
        }
      } catch (error) {
        console.error("Error checking Quickbooks connection:", error);
      }
    };

    checkQuickbooksConnection();
  }, [user]);

  const handleConnect = async () => {
    await network.post(
      `/api/quickbooks/store-user`,
      {
        auth0_user_id: user?.auth0_user_id,
      },
      {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      }
    );
    // const quickbooksOAuthUrl = `${env.APP_QUICKBOOKS_AUTH_URL}?client_id=${env.APP_QUICKBOOKS_CLIENT_ID}&redirect_uri=/api/quickbooks/callback&response_type=code&scope=${env.APP_QUICKBOOKS_SCOPE}&state=quickbooks&prompt=consent`;
    // window.location.href = quickbooksOAuthUrl;
    // todo this should happen on the API
  };

  const hasCanAuthenticate =
    permissions && permissions.includes("canAuthenticate");

  if (connected) {
    return (
      <Button variant="subtle" color="white" leftSection={<IconCheck />}>
        Connected to {companyName}
      </Button>
    );
  }

  return (
    <Button
      onClick={handleConnect}
      variant="white"
      color="dark"
      disabled={!hasCanAuthenticate}
    >
      Connect
    </Button>
  );
}
