import {
  ENTITLEMENT_IS_CUSTOMER_OWNER,
  ENTITLEMENT_IS_SERVICE_PROVIDER,
  ENTITLEMENT_SAMPLE,
} from "../fields/entitlements";

// locked entitlements cannot be modified by any user
export const LockedEntitlements: number[] = [ENTITLEMENT_IS_SERVICE_PROVIDER];

// entitlements available to service provider organizations
export const OrganizationEntitlementsServiceProvider: number[] = [
  ENTITLEMENT_IS_SERVICE_PROVIDER,
  ENTITLEMENT_SAMPLE,
];

// entitlements available to customer organizations
export const OrganizationEntitlementsCustomer: number[] = [ENTITLEMENT_SAMPLE];

// entitlements available to service provider organization users
export const OrganizationUserEntitlementsServiceProvider: number[] = [];

// entitlements available to customer organization users
export const OrganizationUserEntitlementsCustomer: number[] = [
  ENTITLEMENT_IS_CUSTOMER_OWNER,
];
