import { AppShell, Group } from "@mantine/core";
import { AsideToggleButton } from "./Buttons/AsideToggleButton.js";
import { AccountMenu } from "./Menus/AccountMenu.js";
import { ColorSwitcherMenu } from "./Menus/ColorSwitcherMenu.js";
import { CustomerMenu } from "./Menus/CustomerMenu.js";

export function AppHeader() {
  return (
    <AppShell.Header>
      <Group justify="space-between" py="xs" px="md">
        <CustomerMenu />
        <Group gap="xs">
          <AccountMenu />
          <ColorSwitcherMenu />
          <AsideToggleButton />
        </Group>
      </Group>
    </AppShell.Header>
  );
}
