import { archivedAtField } from "../fields/archived_at";
import { createdAtField } from "../fields/created_at";
import {
  customerIdField,
  customerIdFieldRequired,
} from "../fields/customer_id";
import { entitlementsField } from "../fields/entitlements";
import {
  providerIdField,
  providerIdFieldRequired,
} from "../fields/provider_id";
import { userIdField } from "../fields/user_id";
import { entity, EntityType } from "../types/entity";

export const organizationRelationshipUserEntity = entity({
  api: {
    collectionPath: "organization-relationship-users",
    itemPath:
      "organization-relationship-users/[provider_id]/[customer_id]/[user_id]",
  },
  name: "OrganizationRelationshipUser",
  fields: [
    archivedAtField,
    createdAtField,
    customerIdFieldRequired,
    entitlementsField,
    providerIdFieldRequired,
    userIdField,
  ],
  primaryKeyFields: [providerIdField, customerIdField, userIdField],
  tableName: "organization_relationship_users",
});

export type OrganizationRelationshipUserEntity = EntityType<
  typeof organizationRelationshipUserEntity.fields,
  typeof organizationRelationshipUserEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleOrganizationRelationshipUser: OrganizationRelationshipUserEntity =
  {
    archived_at: new Date().toISOString(),
    created_at: new Date().toISOString(),
    customer_id: 765,
    entitlements: [],
    provider_id: 543,
    user_id: 123,
  };
