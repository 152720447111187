import { Container, Grid } from "@mantine/core";
import { useEffect, useState } from "react";

import { ArticleCardImage } from "../../components/Cards/ArticleCardImage.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import {
  CardData,
  Recipe,
  RecipesApiOverviewResponse,
} from "../../types/index.js";

const lgSpanSequence = [4, 8, 8, 4];

export function RecipesOverview() {
  const [cardData, setCardData] = useState<CardData[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/recipes`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        });
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `HTTP error! status: ${response.status} - ${errorText}`
          );
        }
        const data: RecipesApiOverviewResponse = await response.json();

        // Map the API response to match the CardData interface
        const mappedData = data.rows.map((item: Recipe, index: number) => ({
          id: item.id,
          backgroundImage: item.background_image,
          category: item.category,
          title: item.title,
          span: {
            base: 12,
            md: 6,
            lg: lgSpanSequence[index % lgSpanSequence.length],
          },
          link: item.link,
          comingsoon: item.comingsoon ?? false,
        }));

        setCardData(mappedData);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error("Error fetching data:", error);
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />
      <Container fluid my="md" mb={80}>
        {error && <div>Error: {error}</div>}
        <Grid>
          {cardData.map((card) => (
            <Grid.Col span={card.span} key={card.id}>
              <ArticleCardImage
                id={card.id}
                backgroundImage={card.backgroundImage}
                category={card.category}
                title={card.title}
                link={card.link}
                comingsoon={card.comingsoon}
              />
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </Layout>
  );
}
