import { archivedAtField } from "../fields/archived_at";
import { customerIdField } from "../fields/customer_id";
import { dataField } from "../fields/data";
import { idField } from "../fields/id";
import {
  MESSAGE_LIBRARY_ID_USER_SIGN_IN,
  messageField,
} from "../fields/message";
import { providerIdField } from "../fields/provider_id";
import { referenceIdField } from "../fields/reference_x_id";
import {
  REFERENCE_TYPE_USER,
  referenceTypeField,
} from "../fields/reference_x_type";
import { timestampField } from "../fields/timestamp";
import { userIdField } from "../fields/user_id";
import {
  entity,
  EntityType,
  FieldList,
  PrimaryKeyFieldTuple,
} from "../types/entity";

// Message fields
export const fields = [
  archivedAtField,
  customerIdField,
  dataField,
  idField,
  messageField,
  providerIdField,
  referenceIdField(0),
  referenceTypeField(0),
  timestampField,
  userIdField,
] satisfies FieldList;

// Message primary key fields
export const primaryKeyFields = [idField] satisfies PrimaryKeyFieldTuple;

export const messageEntity = entity({
  api: {
    collectionPath: "messages",
    itemPath: "messages/[id]",
  },
  name: "Message",
  fields,
  primaryKeyFields,
  tableName: "messages",
});

export type MessageEntity = EntityType<
  typeof messageEntity.fields,
  typeof messageEntity.primaryKeyFields
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleMessage: MessageEntity = {
  customer_id: 123,
  data: {},
  id: 0,
  message: MESSAGE_LIBRARY_ID_USER_SIGN_IN,
  provider_id: 456,
  reference_0_id: 789,
  reference_0_type: REFERENCE_TYPE_USER,
  timestamp: new Date().toISOString(),
  user_id: 543,
};
