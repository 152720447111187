import { Button, Container, Group, Stack, Text } from "@mantine/core";
import { IconPlus, IconQuestionMark, IconTestPipe } from "@tabler/icons-react";
import { Link } from "react-router-dom";

import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { PrimaryColor } from "../../theme.js";
import {
  capitalizeFirstLetter,
  getEmailDomain,
} from "../../utils/textUtils.js";
import classes from "./welcome.css.js";

export function Welcome() {
  const { user, customer, serviceProvider, isServiceProviderUser } =
    useActiveStates();

  const canAuthenticate = true; // todo - use entitlements to determine admin status

  const greetingName = user
    ? user.nickname || user.given_name || "Hello"
    : "Guest";

  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />
      <div className={classes.wrapper}>
        <Container>
          <Stack gap={10}>
            <h1 className={classes.title}>
              Welcome{" "}
              <Text component="span" c={PrimaryColor} inherit>
                {capitalizeFirstLetter(greetingName)}
              </Text>
              ,
            </h1>

            <Text className={classes.description} color="dimmed">
              {canAuthenticate
                ? `Let's begin by connecting the essential third-party services for ${
                    customer?.name || getEmailDomain(user?.email)
                  }. Meanwhile, Penny is here to answer any of your questions about finance and accounting.`
                : `Ask Penny a question about ${
                    customer?.name || getEmailDomain(user?.email)
                  }, or explore recipes aimed to automate your bookkeeping tasks.`}
            </Text>

            <Group>
              {canAuthenticate && (
                <Link to="/connect/overview">
                  <Button variant="filled" size="lg" radius="xl">
                    Get connected
                  </Button>
                </Link>
              )}
              {serviceProvider && !customer && isServiceProviderUser && (
                <Button radius="xl" size="lg" leftSection={<IconPlus />}>
                  Add a customer
                </Button>
              )}
              <Link to="/penny/overview">
                <Button
                  size="lg"
                  radius="xl"
                  variant="light"
                  leftSection={<IconQuestionMark />}
                >
                  Ask Penny a question
                </Button>
              </Link>
              {customer && serviceProvider && (
                <Link to="/recipes/overview">
                  <Button
                    size="lg"
                    radius="xl"
                    variant="subtle"
                    leftSection={<IconTestPipe size={20} />}
                  >
                    See all your recipes
                  </Button>
                </Link>
              )}
            </Group>
          </Stack>
        </Container>
      </div>
    </Layout>
  );
}
