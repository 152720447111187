import { IntegerField } from "../types/field";

export const REFERENCE_TYPE_USER = 0;

export const ReferenceTypeLabel = {
  [REFERENCE_TYPE_USER]: "User",
};

export function referenceTypeField<X extends number>(
  x: X
): IntegerField<{ final: true; name: `reference_${X}_type` }> {
  return {
    final: true,
    label: `Reference ${x} Type`,
    name: `reference_${x}_type`,
    type: "integer",
  };
}
