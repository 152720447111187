import { IntegerField } from "../types/field";

export const providerIdField: IntegerField<{
  final: true;
  name: "provider_id";
}> = {
  final: true,
  label: "Provider ID",
  name: "provider_id",
  type: "integer",
};

export const providerIdFieldRequired: IntegerField<{
  final: true;
  name: "provider_id";
  required: true;
}> = {
  ...providerIdField,
  required: true,
};
