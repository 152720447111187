import {
  ActionIcon,
  Group,
  Menu,
  ScrollArea,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { IconDots, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

import { OrganizationEntity } from "../../../common/entities/organization.js";
import { OrganizationAvatar } from "../Avatars/OrganizationAvatar.js";
import { OrganizationModal } from "../Modals/OrganizationModal.js";

interface OrganizationsTableProps {
  organizations: OrganizationEntity[];
  onDelete?: (organization: OrganizationEntity) => void;
  onEdit?: (organization: OrganizationEntity) => void;
}

export function OrganizationsTable({
  organizations,
  onDelete,
  onEdit,
}: OrganizationsTableProps) {
  const [editingOrganization, setEditingOrganization] =
    useState<OrganizationEntity | null>(null);
  const [modalOpened, setModalOpened] = useState(false);

  const handleEdit = (organization: OrganizationEntity) => {
    setEditingOrganization(organization);
    setModalOpened(true);
  };

  const handleEditSubmit = async (updatedOrg: Partial<OrganizationEntity>) => {
    if (onEdit && editingOrganization) {
      await onEdit({ ...editingOrganization, ...updatedOrg });
    }
    setModalOpened(false);
    setEditingOrganization(null);
  };

  const rows = organizations.map((organization) => (
    <Table.Tr key={organization.id}>
      <Table.Td pl="lg">
        <Group gap="sm">
          <OrganizationAvatar
            variant="filled"
            size={45}
            radius="sm"
            organization={organization}
            color={organization.theme_primary_color ?? "#228BE6"}
          />
          <Stack gap={0}>
            <Text fw={700}>{organization.name}</Text>
            <Text fz="sm" c="dimmed">
              Client since{" "}
              {new Date(
                organization.created_at ?? Date.now()
              ).toLocaleDateString(undefined, {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </Text>
          </Stack>
        </Group>
      </Table.Td>
      <Table.Td>{organization.primary_domain}</Table.Td>
      <Table.Td>{organization.primary_location}</Table.Td>
      <Table.Td>
        <Menu position="bottom-end" withArrow>
          <Menu.Target>
            <ActionIcon variant="subtle" color="gray">
              <IconDots style={{ width: "70%", height: "70%" }} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              leftSection={<IconPencil size={14} />}
              onClick={() => handleEdit(organization)}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              leftSection={<IconTrash size={14} />}
              color="red"
              onClick={() => onDelete?.(organization)}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <ScrollArea h={`calc(100vh - 140px)`}>
        <Table verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th pl="lg">Organization</Table.Th>
              <Table.Th>Domain</Table.Th>
              <Table.Th>Location</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </ScrollArea>

      <OrganizationModal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setEditingOrganization(null);
        }}
        onSubmit={handleEditSubmit}
        type="service_provider"
        editMode={true}
        initialData={editingOrganization ?? undefined}
      />
    </>
  );
}
