import { IntegerRangeField } from "../types/field";

export const contactTimesField: IntegerRangeField<{
  array: true;
  name: "contact_times";
}> = {
  array: true,
  label: "Contact Times",
  name: "contact_times",
  type: "integer-range",
};
