import { IntegerField } from "../types/field";

export const ENTITLEMENT_IS_SERVICE_PROVIDER = 0 as const;
export const ENTITLEMENT_IS_CUSTOMER_OWNER = 1 as const;
export const ENTITLEMENT_SAMPLE = 2 as const;

export const EntitlementLabels = {
  [ENTITLEMENT_IS_SERVICE_PROVIDER]: "Service Provider",
  [ENTITLEMENT_IS_CUSTOMER_OWNER]: "Customer Owner",
  [ENTITLEMENT_SAMPLE]: "Sample Entitlement",
};

export const entitlementsField: IntegerField<{
  array: true;
  name: "entitlements";
  required: true;
}> = {
  array: true,
  label: "Entitlements",
  name: "entitlements",
  required: true,
  type: "integer",
};
