import { Container, Stack, Text, Title } from "@mantine/core";

import { Layout } from "../components/Layout.js";
import { PageTitle } from "../components/PageTitle.js";
import classes from "./welcome/welcome.css.js";

export function NotFound() {
  return (
    <Layout>
      <PageTitle title="Not Found" />
      <div className={classes.wrapper}>
        <Container size="xl">
          <Stack>
            <Title className={classes.title} order={1}>
              Not Found
            </Title>
            <Text className={classes.description}>
              The page you are looking for does not exist.
            </Text>
          </Stack>
        </Container>
      </div>
    </Layout>
  );
}
