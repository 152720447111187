import { TimestampField } from "../types/field";

export const updatedAtField: TimestampField<{
  auto: true;
  name: "updated_at";
  required: true;
}> = {
  auto: true,
  label: "Updated At",
  name: "updated_at",
  required: true,
  type: "timestamp",
};
