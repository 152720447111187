import { TimestampField } from "../types/field";

export const createdAtField: TimestampField<{
  auto: true;
  name: "created_at";
  required: true;
}> = {
  auto: true,
  label: "Created At",
  name: "created_at",
  required: true,
  type: "timestamp",
};
