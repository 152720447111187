import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { NotificationsOverview } from "../notifications/index.js";

export function ActivityOverview() {
  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />
      <NotificationsOverview isAllActivity={true} />
    </Layout>
  );
}
