import { StringField } from "../types/field";

export const familyNameField: StringField<{
  name: "family_name";
  required: true;
}> = {
  label: "Last Name",
  name: "family_name",
  required: true,
  type: "string",
};
