let guid = 0;

export function css<ClassNames extends object>(
  template: TemplateStringsArray,
  ...params: string[]
): ClassNames {
  const classNames: ClassNames = {} as ClassNames;
  const source = template.map((x, i) => `${x}${params[i] ?? ""}`).join("");

  const styles = source.replace(
    /\.(!?[a-zA-Z][a-zA-Z0-9_-]*)/g,
    (_, className) => {
      if (className.startsWith("!")) {
        return `.${className.slice(1)}`;
      }
      if (className in classNames) {
        return `.${classNames[className as keyof typeof classNames]}`;
      }
      const replacementClassName = `_${guid++}_${className}`;
      classNames[className as keyof typeof classNames] =
        replacementClassName as ClassNames[keyof ClassNames];
      return `.${replacementClassName}`;
    }
  );

  const styleElement = document.createElement("style");
  styleElement.textContent = styles;
  document.head.appendChild(styleElement);

  return classNames;
}
